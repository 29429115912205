import React, { useState, useEffect } from "react";
import "./promo.css";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import imageCompression from "browser-image-compression";
// import { AccountCircleOutlined } from "@mui/icons-material";
import { useUserContext } from "../context/userContext";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import ProgressBar from "../components/progressBar/ProgressBar";
// import useStorage from "../hooks/useStorage";

const Promo = ({ setIsPromoModalOpen, sanCardId, sanname }) => {
  // const avatarRef = useRef();
  const [isChecked, setIsChecked] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [gatePic, setGatePic] = useState(null);
  const [promoCodeList, setPromoCodeList] = useState(null);
  const { error, setError } = useUserContext();

  const types = ["image/png", "image/jpeg", "image/jpg"];

  useEffect(() => {
    const promoList = async () => {
      const docRef = doc(db, "promo", "a");
      const docSnap = await getDoc(docRef);
      setPromoCodeList(docSnap.data().nick);
      // setIsChecked(docSnap.data().nick.includes(promoCode));
      if (error) setError("");
      // console.log(isChecked);
    };
    promoList();
    // eslint-disable-next-line
  }, []);

  const handleVerify = (e) => {
    setError("");
    e.preventDefault();
    if (promoCodeList.includes(promoCode)) {
      setIsChecked(true);
    } else {
      setError("프로모코드(PromoCode)가 일치하지 않습니다");
    }
  };
  const handleGatePicInput = async (e) => {
    e.preventDefault();
    setError(null);
    let selected = e.target.files[0];
    if (selected && types.includes(selected.type)) {
      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1000,
      };
      try {
        const compressedFile = await imageCompression(selected, options);
        setGatePic(compressedFile);
      } catch (error) {}
    } else {
      // setGatePic(null);
      setError("이미지타입을 확인해주세요(*.png, *.jpeg)");
    }
  };

  return (
    <>
      <div className="promo__modal__content animate">
        <div className="promo__upper__container">
          <div
            className="x__close"
            title="Close Modal"
            onClick={() => setIsPromoModalOpen(false)}
          >
            &times;
          </div>
          {error && <span className="error_span">{error}</span>}
          <div className="promo__form">
            <label className="promo__main__title">PromoCode Checker</label>
            {!isChecked && (
              <form onSubmit={handleVerify}>
                <div className="code__verification">
                  <input
                    className="promocode__input"
                    type="text"
                    placeholder="Promo Code"
                    required
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                  />
                  <button type="submit" className="promocode__input__btn">
                    Check
                  </button>
                </div>
              </form>
            )}
          </div>
          {isChecked && (
            <>
              <div className="promo__checked__container">
                <form>
                  <label htmlFor="gatePic" className="gatePic__selection">
                    <AddAPhotoOutlinedIcon />
                    공지 표제사진
                  </label>
                  <input
                    id="gatePic"
                    type="file"
                    // value={gatePic}
                    onChange={handleGatePicInput}
                  />
                  {gatePic && (
                    <ProgressBar
                      sanCardId={sanCardId}
                      sanname={sanname}
                      gatePic={gatePic}
                      setGatePic={setGatePic}
                      setIsPromoModalOpen={setIsPromoModalOpen}
                    />
                  )}
                </form>
              </div>
            </>
          )}
        </div>

        <div className="promo__container__bottom">
          <p className="promo__notice">
            프로모코드(PromoCode)는 50회 이상의 공지한 분의 정보공간이며, 아바타
            및 공지 표제사진을 올릴 수 있는 기능이 제공됩니다.
          </p>
          <button
            type="button"
            onClick={() => setIsPromoModalOpen(false)}
            className="promo__cancelbtn"
          >
            <span className="promo__cancel">취소</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Promo;

/* <input */

// style={inputStyle}
// className="up__name"
// type="text"
// placeholder="별칭"
// required
// />

// <label>비밀번호</label>
// <input
// style={inputStyle}
// type="password"
// placeholder="6자 이상 입력"
// name="psw"
// required
// />
//  <label htmlFor="avatar" className="avatar__selection">
// <AccountCircleIcon />
// 아바타 - 선택
// </label>
// <input

// id="avatar"
// type="file"
// ref={avatarRef}
// onChange={(e) => setAvatar(e.target.files[0])}
// />
// </div>

// <button className="login__btn" type="submit" disabled={loading}>
// {loading ? "등록 중 ..." : "등록"}
// </button>
// <label>
// <input type="checkbox" checked="checked" name="remember" />
// Remember me
// </label>
