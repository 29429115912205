const sans = [
  {
    rank: 1,
    sanname: "한라산",
    sanheight: 1947.3,
    province: "제주",
    address: "제주특별자치도",
    peak: "백록담",
    latitude: 33.36191487,
    longitude: 126.5333176,
    n_park: true,
    san100: true,
    black100: true,
    ccLat: 33.364805,
    ccLng: 126.542671,
    cZoom: 10,
    img: "./images/san3_s.jpg",
  },
  {
    rank: 2,
    sanname: "지리산",
    sanheight: 1915.4,
    province: "전라북도",
    address:
      "전라북도 남원시, 전라남도 구례군, 경상남도 하동군ㆍ산청군ㆍ함양군",
    peak: "천왕봉",
    latitude: 35.33690972,
    longitude: 127.7306228,
    n_park: true,
    san100: true,
    black100: true,
    ccLat: 35.716705,
    ccLng: 127.144185,
    cZoom: 8,
    img: "./images/san2_s.jpg",
  },
  {
    rank: 3,
    sanname: "반야봉",
    sanheight: 1732,
    province: "전라남도",
    address: "전라남도 구례군, 전라북도 남원시",
    peak: null,
    latitude: 35.3164875,
    longitude: 127.5694625,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 34.8194,
    ccLng: 126.893113,
    cZoom: 8,
  },
  {
    rank: 4,
    sanname: "설악산",
    sanheight: 1708.1,
    province: "강원도",
    address: "강원도 속초시 설악동, 인제군 북면ㆍ인제읍, 양양군 서면ㆍ강현면",
    peak: "대청봉",
    latitude: 38.119125,
    longitude: 128.4653611,
    n_park: true,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
    img: "./images/san1_s.jpg",
  },
  {
    rank: 5,
    sanname: "덕유산",
    sanheight: 1614.2,
    province: "전라북도",
    address: "전라북도 무주군ㆍ장수군, 경상남도 거창군ㆍ함양군",
    peak: "향적봉",
    latitude: 35.85988335,
    longitude: 127.7463209,
    n_park: true,
    san100: true,
    black100: true,
    ccLat: 35.716705,
    ccLng: 127.144185,
    cZoom: 8,
  },
  {
    rank: 6,
    sanname: "계방산",
    sanheight: 1579.1,
    province: "강원도",
    address: "강원도 홍천군 내면, 평창군 용편면ㆍ진부면",
    peak: null,
    latitude: 37.72834167,
    longitude: 128.4654972,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 7,
    sanname: "함백산",
    sanheight: 1572,
    province: "강원도",
    address: "강원도&nbsp;정선군과&nbsp;태백시의 경계",
    peak: null,
    latitude: 37.16116389,
    longitude: 128.917625,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 8,
    sanname: "태백산",
    sanheight: 1566.7,
    province: "강원도",
    address: "강원도 태백시, 경상북도 봉화군 석포면",
    peak: "장군봉",
    latitude: 37.0962988015,
    longitude: 128.917050362,
    n_park: true,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 9,
    sanname: "오대산",
    sanheight: 1565.4,
    province: "강원도",
    address: "강원도 평창군 진부면, 홍천군 내면, 강릉시 연곡면",
    peak: "비로봉",
    latitude: 37.79459051,
    longitude: 128.5436089,
    n_park: true,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 10,
    sanname: "가리왕산",
    sanheight: 1561.9,
    province: "강원도",
    address: "강원도 정선군 북평면ㆍ정선읍 회동리, 평창군 진부면",
    peak: null,
    latitude: 37.46150556,
    longitude: 128.5633639,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 11,
    sanname: "화악산",
    sanheight: 1468.3,
    province: "경기도",
    address: "경기도 가평군 북면, 강원도 화천군 사내면",
    peak: "중봉",
    latitude: 37.9895988,
    longitude: 127.4984162,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.7,
    ccLng: 127.046277,
    cZoom: 9,
  },
  {
    rank: 12,
    sanname: "방태산",
    sanheight: 1445.7,
    province: "강원도",
    address: "강원도 인제군 기린면ㆍ상남면, 홍천군 내면",
    peak: "주억봉",
    latitude: 37.88815,
    longitude: 128.39009,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 13,
    sanname: "소백산",
    sanheight: 1439.7,
    province: "경상북도",
    address: "경상북도 영주시 풍기읍, 충청북도 단양군 단양읍",
    peak: "비로봉",
    latitude: 36.95748333,
    longitude: 128.4848722,
    n_park: true,
    san100: true,
    black100: true,
    ccLat: 36.248647,
    ccLng: 128.664734,
    cZoom: 9,
  },
  {
    rank: 14,
    sanname: "가야산",
    sanheight: 1432.6,
    province: "경상남도",
    address: "경상남도 합천군ㆍ거창군, 경상북도 성주군",
    peak: "우두봉",
    latitude: 35.82359722,
    longitude: 128.1204972,
    n_park: true,
    san100: true,
    black100: true,
    ccLat: 35.259787,
    ccLng: 128.664734,
    cZoom: 8,
  },
  {
    rank: 15,
    sanname: "점봉산",
    sanheight: 1426,
    province: "강원도",
    address: "강원도 양양군 서면, 인제군 인제읍ㆍ기린면",
    peak: null,
    latitude: 38.049173,
    longitude: 128.427429,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 16,
    sanname: "두타산",
    sanheight: 1357,
    province: "강원도",
    address: "강원도 동해시 삼화동, 삼척시 미로면ㆍ하장면",
    peak: null,
    latitude: 37.42663889,
    longitude: 129.0044278,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 17,
    sanname: "백덕산",
    sanheight: 1350.1,
    province: "강원도",
    address: "강원도 평창군 방림면, 횡성군 안흥면, 영월군 수주면",
    peak: null,
    latitude: 37.39643056,
    longitude: 128.2935,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 18,
    sanname: "노인봉",
    sanheight: 1338,
    province: "강원도",
    address: "강원도 강릉시 연곡면",
    peak: null,
    latitude: 37.78266389,
    longitude: 128.6397806,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 19,
    sanname: "대암산",
    sanheight: 1312.6,
    province: "강원도",
    address: "강원도 양구군 동면, 인제군 서화면",
    peak: null,
    latitude: 38.211193,
    longitude: 128.135084,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 20,
    sanname: "치악산",
    sanheight: 1282,
    province: "강원도",
    address: "강원도 원주시, 횡성군, 영월군",
    peak: "비로봉",
    latitude: 37.365125,
    longitude: 128.0556444,
    n_park: true,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 21,
    sanname: "명지산",
    sanheight: 1252.3,
    province: "경기도",
    address: "경기도 가평군 북면ㆍ하면",
    peak: null,
    latitude: 37.94153202,
    longitude: 127.4322069,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.7,
    ccLng: 127.046277,
    cZoom: 9,
  },
  {
    rank: 22,
    sanname: "민주지산",
    sanheight: 1241.7,
    province: "충청북도",
    address: "충청북도 영동군, 전라북도 무주군, 경상북도 김천시",
    peak: null,
    latitude: 36.03980956,
    longitude: 127.8492505,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 36.628503,
    ccLng: 127.929344,
    cZoom: 8,
  },
  {
    rank: 23,
    sanname: "가지산 ",
    sanheight: 1240.9,
    province: "울산광역시",
    address: "울산광역시 울주군, 경상북도 청도군, 경상남도 밀양시",
    peak: null,
    latitude: 35.62027778,
    longitude: 129.0029444,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 35.519301,
    ccLng: 129.139078,
    cZoom: 10,
  },
  {
    rank: 24,
    sanname: "장안산",
    sanheight: 1237.4,
    province: "전라북도",
    address: "전라북도 장수군 장수읍, 계남면",
    peak: null,
    latitude: 35.62921274,
    longitude: 127.595046,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 35.716705,
    ccLng: 127.144185,
    cZoom: 8,
  },
  {
    rank: 25,
    sanname: "백운산",
    sanheight: 1222.2,
    province: "전라남도",
    address: "전라남도 광양시 진상면ㆍ옥룡면ㆍ봉강면ㆍ다압면, 구례군 간전면",
    peak: "상봉",
    latitude: 35.10637222,
    longitude: 127.6213444,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 34.8194,
    ccLng: 126.893113,
    cZoom: 8,
  },
  {
    rank: 26,
    sanname: "운문산",
    sanheight: 1195.1,
    province: "경상북도",
    address: "경상북도 청도군 운문면, 경상남도 밀양시 산내면",
    peak: null,
    latitude: 35.615506,
    longitude: 128.959725,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 36.248647,
    ccLng: 128.664734,
    cZoom: 9,
  },
  {
    rank: 27,
    sanname: "황석산",
    sanheight: 1192.5,
    province: "경상남도",
    address: "경상남도 함양군 안의면, 서하면, 서상면",
    peak: null,
    latitude: 35.65517361,
    longitude: 127.7554706,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 35.259787,
    ccLng: 128.664734,
    cZoom: 8,
  },
  {
    rank: 28,
    sanname: "팔공산",
    sanheight: 1192.3,
    province: "경상북도",
    address: "경상북도 군위군 부계면, 영천시 신녕면, 대구광역시 동구",
    peak: "비로봉",
    latitude: 36.01637665,
    longitude: 128.6945558,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 36.248647,
    ccLng: 128.664734,
    cZoom: 9,
  },
  {
    rank: 29,
    sanname: "무등산",
    sanheight: 1186.8,
    province: "광주광역시",
    address: "광주광역시 동구, 전라남도 담양군 남면ㆍ화순군 이서면",
    peak: "서석대",
    latitude: 35.12074014,
    longitude: 127.0027514,
    n_park: true,
    san100: true,
    black100: true,
    ccLat: 35.126033,
    ccLng: 126.831302,
    cZoom: 10,
  },
  {
    rank: 30,
    sanname: "바래봉",
    sanheight: 1165,
    province: "전라북도",
    address: "전라북도 남원시 운봉읍",
    peak: null,
    latitude: 35.42181444,
    longitude: 127.5763203,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 35.716705,
    ccLng: 127.144185,
    cZoom: 8,
  },
  {
    rank: 31,
    sanname: "신불산",
    sanheight: 1159.3,
    province: "울산광역시",
    address: "울산광역시 울주군 삼남면ㆍ상북면",
    peak: null,
    latitude: 35.53936111,
    longitude: 129.0541111,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 35.519301,
    ccLng: 129.139078,
    cZoom: 10,
  },
  {
    rank: 32,
    sanname: "용문산",
    sanheight: 1157.1,
    province: "경기도",
    address: "경기도 양평군 용문면ㆍ옥천면",
    peak: null,
    latitude: 37.56214958,
    longitude: 127.5485971,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.7,
    ccLng: 127.046277,
    cZoom: 9,
  },
  {
    rank: 33,
    sanname: "운장산",
    sanheight: 1125.8,
    province: "전라북도",
    address: "전라북도 진안군 주천면, 부귀면, 정천면, 완주군 동상면",
    peak: "운장대",
    latitude: 35.91128056,
    longitude: 127.3577,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 35.716705,
    ccLng: 127.144185,
    cZoom: 8,
  },
  {
    rank: 34,
    sanname: "재약산",
    sanheight: 1119.1,
    province: "경상남도",
    address: "경상남도 밀양시 단장면ㆍ산내면, 울산광역시 울주군 상북면",
    peak: "수미봉",
    latitude: 35.54541667,
    longitude: 128.9805556,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 35.259787,
    ccLng: 128.664734,
    cZoom: 8,
  },
  {
    rank: 35,
    sanname: "황매산",
    sanheight: 1113.1,
    province: "경상남도",
    address: "경상남도 합천군 대병면ㆍ가회면, 산청군 차황면",
    peak: null,
    latitude: 35.49429306,
    longitude: 127.9746603,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 35.259787,
    ccLng: 128.664734,
    cZoom: 8,
  },
  {
    rank: 36,
    sanname: "황악산",
    sanheight: 1111.4,
    province: "경상북도",
    address: "경상북도 김천시 대항면",
    peak: null,
    latitude: 36.11807573,
    longitude: 127.9668221,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 36.248647,
    ccLng: 128.664734,
    cZoom: 9,
  },
  {
    rank: 37,
    sanname: "주흘산",
    sanheight: 1108.4,
    province: "경상북도",
    address: "경상북도 문경시 문경읍",
    peak: "영봉",
    latitude: 36.78856667,
    longitude: 128.1012667,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 36.248647,
    ccLng: 128.664734,
    cZoom: 9,
  },
  {
    rank: 38,
    sanname: "월악산",
    sanheight: 1095.3,
    province: "충청북도",
    address: "충청북도 제천시 한수면, 덕산면",
    peak: "영봉",
    latitude: 36.88597,
    longitude: 128.1060722,
    n_park: true,
    san100: true,
    black100: true,
    ccLat: 36.628503,
    ccLng: 127.929344,
    cZoom: 8,
  },
  {
    rank: 39,
    sanname: "비슬산",
    sanheight: 1083.4,
    province: "대구광역시",
    address: "대구광역시 달성군 옥포면ㆍ유가면ㆍ가창면, 경상북도 청도군 각북면",
    peak: "천왕봉",
    latitude: 35.71544505,
    longitude: 128.523803,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 35.798838,
    ccLng: 128.583052,
    cZoom: 10,
  },
  {
    rank: 40,
    sanname: "황장산",
    sanheight: 1078.9,
    province: "경상북도",
    address: "경상북도 문경시 동로면, 충청북도 제천시",
    peak: null,
    latitude: 36.813091,
    longitude: 128.276144,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 36.248647,
    ccLng: 128.664734,
    cZoom: 9,
  },
  {
    rank: 41,
    sanname: "덕항산",
    sanheight: 1072.9,
    province: "강원도",
    address: "강원도 삼척시 신기면, 태백시 하사미동",
    peak: null,
    latitude: 37.30882222,
    longitude: 129.012625,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 42,
    sanname: "연인산",
    sanheight: 1068,
    province: "경기도",
    address: "경기도 가평군 가평읍 승안면, 북면 백둔리, 하면 상판리",
    peak: null,
    latitude: 37.898784,
    longitude: 127.414388,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 37.7,
    ccLng: 127.046277,
    cZoom: 9,
  },
  {
    rank: 43,
    sanname: "속리산",
    sanheight: 1058.4,
    province: "경상북도",
    address: "경상북도 상주시 화북면, 충청북도 보은군 내속리면",
    peak: "천왕봉",
    latitude: 36.54319296,
    longitude: 127.8708036,
    n_park: true,
    san100: true,
    black100: true,
    ccLat: 36.248647,
    ccLng: 128.664734,
    cZoom: 9,
  },
  {
    rank: 44,
    sanname: "가리산",
    sanheight: 1050.9,
    province: "강원도",
    address: "강원도 홍천군 두촌면ㆍ화촌면, 춘천시 북산면ㆍ동면",
    peak: null,
    latitude: 37.871322,
    longitude: 127.956411,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 45,
    sanname: "적상산",
    sanheight: 1030.6,
    province: "전라북도",
    address: "전라북도 무주군 적상면",
    peak: null,
    latitude: 35.947726,
    longitude: 127.69115,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 35.716705,
    ccLng: 127.144185,
    cZoom: 8,
  },
  {
    rank: 46,
    sanname: "태화산",
    sanheight: 1027.5,
    province: "강원도",
    address: "강원도 영월군 영월읍, 충청북도 단양군 영춘면",
    peak: null,
    latitude: 37.11759167,
    longitude: 128.4853833,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 47,
    sanname: "조령산",
    sanheight: 1017,
    province: "경상북도",
    address: "경북 문경시, 충북 괴산군",
    peak: null,
    latitude: 36.77145556,
    longitude: 128.0442444,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 36.248647,
    ccLng: 128.664734,
    cZoom: 9,
  },
  {
    rank: 48,
    sanname: "금수산",
    sanheight: 1015.8,
    province: "충청북도",
    address: "충청북도 제천시 수산면, 단양군 적성면",
    peak: null,
    latitude: 36.984725,
    longitude: 128.2569056,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 36.628503,
    ccLng: 127.929344,
    cZoom: 8,
  },
  {
    rank: 49,
    sanname: "구봉산",
    sanheight: 1002,
    province: "전라북도",
    address: "전북 진안군 주천면 운봉리 구봉산",
    peak: "천왕봉",
    latitude: 35.92312372,
    longitude: 127.4166319,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 35.716705,
    ccLng: 127.144185,
    cZoom: 8,
  },
  {
    rank: 50,
    sanname: "응봉산",
    sanheight: 999.7,
    province: "강원도",
    address: "강원도 삼척시 가곡면ㆍ원덕읍, 경상북도 울진군 북면",
    peak: null,
    latitude: 37.07658889,
    longitude: 129.2305167,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 51,
    sanname: "희양산",
    sanheight: 996.4,
    province: "경상북도",
    address: "경상북도 문경시 가은읍, 충청북도 괴산군 연풍면",
    peak: null,
    latitude: 36.71473056,
    longitude: 128.0050361,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 36.248647,
    ccLng: 128.664734,
    cZoom: 9,
  },
  {
    rank: 52,
    sanname: "성인봉",
    sanheight: 986.5,
    province: "경상북도",
    address: "경상북도 울릉군 울릉읍 서면ㆍ북면",
    peak: null,
    latitude: 37.50325,
    longitude: 130.866152,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 36.248647,
    ccLng: 128.664734,
    cZoom: 9,
  },
  {
    rank: 53,
    sanname: "금오산",
    sanheight: 976.5,
    province: "경상북도",
    address: "경상북도 구미시 칠곡군 북삼읍, 김천시 남면",
    peak: "현월봉",
    latitude: 36.09178611,
    longitude: 128.300225,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 36.248647,
    ccLng: 128.664734,
    cZoom: 9,
  },
  {
    rank: 54,
    sanname: "청화산",
    sanheight: 970,
    province: "충청북도",
    address: "경상북도 상주시와 문경시, 충청북도의 괴산군",
    peak: null,
    latitude: 36.60371149,
    longitude: 127.9204332,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 36.628503,
    ccLng: 127.929344,
    cZoom: 8,
  },
  {
    rank: 55,
    sanname: "도락산",
    sanheight: 965.3,
    province: "충청북도",
    address: "충청북도 단양군  단성면, 대강면",
    peak: null,
    latitude: 36.85630278,
    longitude: 128.311325,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 36.628503,
    ccLng: 127.929344,
    cZoom: 8,
  },
  {
    rank: 56,
    sanname: "운악산",
    sanheight: 934.7,
    province: "경기도",
    address: "경기도 가평군 하면, 포천시 화현면",
    peak: "만경대",
    latitude: 37.87670561,
    longitude: 127.3253579,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.7,
    ccLng: 127.046277,
    cZoom: 9,
  },
  {
    rank: 57,
    sanname: "대야산",
    sanheight: 931,
    province: "경상북도",
    address: "경상북도 문경시 가은읍, 충청북도 괴산군 청천면",
    peak: null,
    latitude: 36.6691961,
    longitude: 127.9295186,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 36.248647,
    ccLng: 128.664734,
    cZoom: 9,
  },
  {
    rank: 58,
    sanname: "감악산(원주)",
    sanheight: 930,
    province: "강원도",
    address: "충청북도 제천시 봉양웁, 강원도 원주시 신림면 경계",
    peak: null,
    latitude: 37.23061456,
    longitude: 128.1412214,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 59,
    sanname: "명성산",
    sanheight: 922,
    province: "강원도",
    address: "강원도 철원군 갈말읍, 경기도 포천시 영북면ㆍ이동면",
    peak: null,
    latitude: 38.106967,
    longitude: 127.337725,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 60,
    sanname: "천성산",
    sanheight: 920.2,
    province: "경상남도",
    address: "경상남도 양산시 하북면, 상북면",
    peak: null,
    latitude: 35.40158333,
    longitude: 129.1062778,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 35.259787,
    ccLng: 128.664734,
    cZoom: 8,
  },
  {
    rank: 61,
    sanname: "서대산",
    sanheight: 904.1,
    province: "충청북도",
    address: "충청남도 금산군 추부면ㆍ군북면, 충청북도 옥천군 군서면",
    peak: null,
    latitude: 36.219949,
    longitude: 127.537815,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 36.628503,
    ccLng: 127.929344,
    cZoom: 8,
  },
  {
    rank: 62,
    sanname: "백운산(포천)",
    sanheight: 903,
    province: "경기도",
    address: "경기도 포천시 이동면, 강원도 화천군 사내면",
    peak: null,
    latitude: 38.074639,
    longitude: 127.444075,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 37.7,
    ccLng: 127.046277,
    cZoom: 9,
  },
  {
    rank: 63,
    sanname: "공작산",
    sanheight: 887.4,
    province: "강원도",
    address: "강원도 홍천군 동면, 화촌면",
    peak: null,
    latitude: 37.715553,
    longitude: 128.010058,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 64,
    sanname: "조계산",
    sanheight: 887.3,
    province: "전라남도",
    address: "전라남도 순천시 승주읍ㆍ송광면",
    peak: "장군봉",
    latitude: 35.0013,
    longitude: 127.3136333,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 34.8194,
    ccLng: 126.893113,
    cZoom: 8,
  },
  {
    rank: 65,
    sanname: "축령산",
    sanheight: 887.1,
    province: "경기도",
    address: "경기도 남양주시 수동면, 가평군 상면",
    peak: null,
    latitude: 37.752874,
    longitude: 127.333039,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 37.7,
    ccLng: 127.046277,
    cZoom: 9,
  },
  {
    rank: 66,
    sanname: "백운산(정선)",
    sanheight: 883.5,
    province: "강원도",
    address: "강원도 정선군 신동읍, 평창군 미탄면",
    peak: null,
    latitude: 37.299183,
    longitude: 128.579865,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 67,
    sanname: "대둔산",
    sanheight: 878.9,
    province: "충청남도",
    address: "충청남도 논산시 벌곡면ㆍ금산군 진산면, 전라북도 완주군 운주면",
    peak: "마천대",
    latitude: 36.12458889,
    longitude: 127.3204722,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 36.557229,
    ccLng: 126.779757,
    cZoom: 8,
  },
  {
    rank: 68,
    sanname: "용화산",
    sanheight: 877.8,
    province: "강원도",
    address: "강원도 화천군 간동면ㆍ하남면, 춘천시 사북면",
    peak: null,
    latitude: 38.03818056,
    longitude: 127.7479917,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 69,
    sanname: "구병산",
    sanheight: 876.3,
    province: "경상북도",
    address: "경상북도 상주신 화북면, 충청북도 보은군 마로면ㆍ속리산면",
    peak: null,
    latitude: 36.46958409,
    longitude: 127.8617336,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 36.248647,
    ccLng: 128.664734,
    cZoom: 9,
  },
  {
    rank: 70,
    sanname: "청량산",
    sanheight: 869.7,
    province: "경상북도",
    address: "경상북도 봉화군 명호면ㆍ재산면, 안동시 도산면ㆍ예안면",
    peak: "장인봉",
    latitude: 36.79433611,
    longitude: 128.9085528,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 36.248647,
    ccLng: 128.664734,
    cZoom: 9,
  },
  {
    rank: 71,
    sanname: "유명산",
    sanheight: 864,
    province: "경기도",
    address: "경기도 가평군 설악면, 양평군 옥천면",
    peak: null,
    latitude: 37.57528333,
    longitude: 127.4865722,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.7,
    ccLng: 127.046277,
    cZoom: 9,
  },
  {
    rank: 72,
    sanname: "계룡산",
    sanheight: 846.5,
    province: "대전광역시",
    address:
      "대전광역시, 충청남도 공주시 계룡면, 논산시 상월면, 계룡시 신도안면",
    peak: "관음봉",
    latitude: 36.35201103,
    longitude: 127.200346,
    n_park: true,
    san100: true,
    black100: true,
    ccLat: 36.321655,
    ccLng: 127.178953,
    cZoom: 9,
  },
  {
    rank: 73,
    sanname: "북한산",
    sanheight: 835.6,
    province: "서울특별시",
    address: "서울특별시 강북구ㆍ성북구ㆍ종로구ㆍ은평구, 경기도 고양시ㆍ양주시",
    peak: "백운대",
    latitude: 37.65869404,
    longitude: 126.9779634,
    n_park: true,
    san100: true,
    black100: true,
    ccLat: 37.5642135,
    ccLng: 127.0016985,
    cZoom: 9,
  },
  {
    rank: 74,
    sanname: "월출산",
    sanheight: 810.7,
    province: "전라남도",
    address: "전라남도 영암군 영암읍ㆍ군서면ㆍ학산면, 강진군 성전면",
    peak: "천황봉",
    latitude: 34.76660278,
    longitude: 126.7040306,
    n_park: true,
    san100: true,
    black100: true,
    ccLat: 34.8194,
    ccLng: 126.893113,
    cZoom: 8,
  },
  {
    rank: 75,
    sanname: "천마산",
    sanheight: 810.3,
    province: "경기도",
    address: "경기도 남양주시 화도읍, 오남읍",
    peak: null,
    latitude: 37.680237,
    longitude: 127.273314,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 37.7,
    ccLng: 127.046277,
    cZoom: 9,
  },
  {
    rank: 76,
    sanname: "금정산 ",
    sanheight: 800.8,
    province: "부산광역시",
    address: "부산광역시 금정구ㆍ북구, 경상남도 양산시",
    peak: "고당봉",
    latitude: 35.28013889,
    longitude: 129.0505833,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 35.198362,
    ccLng: 129.053922,
    cZoom: 10,
  },
  {
    rank: 77,
    sanname: "모악산",
    sanheight: 795.2,
    province: "전라북도",
    address: "전라북도 김제시 금산면, 전주시 완산구, 완주군 구이면",
    peak: null,
    latitude: 35.72858056,
    longitude: 127.0851722,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 35.716705,
    ccLng: 127.144185,
    cZoom: 8,
  },
  {
    rank: 78,
    sanname: "오서산",
    sanheight: 790,
    province: "충청남도",
    address: "충남 보령시 청라면&nbsp;오서산길",
    peak: null,
    latitude: 36.45836667,
    longitude: 126.6593306,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 36.557229,
    ccLng: 126.779757,
    cZoom: 8,
  },
  {
    rank: 79,
    sanname: "오봉산",
    sanheight: 777.9,
    province: "강원도",
    address: "강원도 춘천시 북산면, 화천군 간동면",
    peak: null,
    latitude: 37.99962064,
    longitude: 127.8075922,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 80,
    sanname: "내장산",
    sanheight: 763.5,
    province: "전라북도",
    address: "전라북도 정읍시 내장동, 순창군 쌍치면ㆍ복흥면",
    peak: "신선봉",
    latitude: 35.47832036,
    longitude: 126.889075,
    n_park: true,
    san100: true,
    black100: true,
    ccLat: 35.716705,
    ccLng: 127.144185,
    cZoom: 8,
  },
  {
    rank: 81,
    sanname: "무학산",
    sanheight: 761.4,
    province: "경상남도",
    address: "경상남도 창원시 교방동ㆍ두척동ㆍ내서읍",
    peak: null,
    latitude: 35.211063,
    longitude: 128.535774,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 35.259787,
    ccLng: 128.664734,
    cZoom: 8,
  },
  {
    rank: 82,
    sanname: "화왕산",
    sanheight: 757.7,
    province: "경상남도",
    address: "경상남도 창녕군 창녕읍, 고암면",
    peak: null,
    latitude: 35.54716667,
    longitude: 128.5316389,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 35.259787,
    ccLng: 128.664734,
    cZoom: 8,
  },
  {
    rank: 83,
    sanname: "백암산",
    sanheight: 741.2,
    province: "전라북도",
    address: "전라북도 순창군 복흥면, 전라남도 장성군 북하면",
    peak: "상왕봉",
    latitude: 35.46128611,
    longitude: 126.8684694,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 35.716705,
    ccLng: 127.144185,
    cZoom: 8,
  },
  {
    rank: 84,
    sanname: "도봉산",
    sanheight: 740.2,
    province: "서울특별시",
    address: "서울특별시 도봉구, 경기도 의정부시 호원동ㆍ양주시 장흥면",
    peak: "신선대",
    latitude: 37.6987565,
    longitude: 127.015276,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.5642135,
    ccLng: 127.0016985,
    cZoom: 9,
  },
  {
    rank: 85,
    sanname: "동악산",
    sanheight: 736.8,
    province: "전라남도",
    address: "전남 곡성군 곡성읍 도림로 175",
    peak: "시루봉",
    latitude: 35.282,
    longitude: 127.251925,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 34.8194,
    ccLng: 126.893113,
    cZoom: 8,
  },
  {
    rank: 86,
    sanname: "방장산",
    sanheight: 733.6,
    province: "전라남도",
    address: "전라남도 장성군, 전라북도 고창군 신림면ㆍ정읍시 입암면",
    peak: null,
    latitude: 35.455749,
    longitude: 126.754389,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 34.8194,
    ccLng: 126.893113,
    cZoom: 8,
  },
  {
    rank: 87,
    sanname: "추월산",
    sanheight: 731.2,
    province: "전라남도",
    address: "전라남도 담양군 용면, 전라북도 순창군 복흥면",
    peak: null,
    latitude: 35.399211,
    longitude: 126.975611,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 34.8194,
    ccLng: 126.893113,
    cZoom: 8,
  },
  {
    rank: 88,
    sanname: "천관산",
    sanheight: 724.3,
    province: "전라남도",
    address: "전라남도 장흥군 관산읍, 대덕읍",
    peak: "연대봉",
    latitude: 34.53166944,
    longitude: 126.9196972,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 34.8194,
    ccLng: 126.893113,
    cZoom: 8,
  },
  {
    rank: 89,
    sanname: "주왕산",
    sanheight: 722.1,
    province: "경상북도",
    address: "경상북도 청송군 청송읍ㆍ부동면, 영덕군 지품면ㆍ달산면",
    peak: null,
    latitude: 36.38935,
    longitude: 129.1623944,
    n_park: true,
    san100: true,
    black100: true,
    ccLat: 36.248647,
    ccLng: 128.664734,
    cZoom: 9,
  },
  {
    rank: 90,
    sanname: "천태산",
    sanheight: 715.2,
    province: "충청북도",
    address: "충청북도 영동군 양산면, 충청남도 금산군 제원면",
    peak: null,
    latitude: 36.15916944,
    longitude: 127.6001,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 36.628503,
    ccLng: 127.929344,
    cZoom: 8,
  },
  {
    rank: 91,
    sanname: "내연산 ",
    sanheight: 711.3,
    province: "경상북도",
    address: "경상북도 포항시 송라면ㆍ청하면ㆍ죽장면, 영덕군 남정면",
    peak: "삼지봉",
    latitude: 36.27864444,
    longitude: 129.2898611,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 36.248647,
    ccLng: 128.664734,
    cZoom: 9,
  },
  {
    rank: 92,
    sanname: "금산",
    sanheight: 704.9,
    province: "경상남도",
    address: "경상남도 남해군 상주면, 이동면, 삼동면",
    peak: null,
    latitude: 34.756106,
    longitude: 127.977451,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 35.259787,
    ccLng: 128.664734,
    cZoom: 8,
  },
  {
    rank: 93,
    sanname: "두륜산",
    sanheight: 700,
    province: "전라남도",
    address: "전라남도 해남군 삼산면ㆍ북일면ㆍ북평면ㆍ현산면",
    peak: "가련봉",
    latitude: 34.47191389,
    longitude: 126.6375611,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 34.8194,
    ccLng: 126.893113,
    cZoom: 8,
  },
  {
    rank: 94,
    sanname: "광덕산",
    sanheight: 699,
    province: "충청남도",
    address: "충청남도 천안시 동남구 광덕면 광덕사길 30",
    peak: null,
    latitude: 36.68781063,
    longitude: 127.0278751,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 36.557229,
    ccLng: 126.779757,
    cZoom: 8,
  },
  {
    rank: 95,
    sanname: "마이산",
    sanheight: 687.4,
    province: "전라북도",
    address: "전라북도 진안군 진안읍ㆍ마령면",
    peak: "암마이봉",
    latitude: 35.76055,
    longitude: 127.4115,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 35.716705,
    ccLng: 127.144185,
    cZoom: 8,
  },
  {
    rank: 96,
    sanname: "가야산(충남)",
    sanheight: 678,
    province: "충청남도",
    address: "충청남도 서산시 운산면ㆍ해미면ㆍ예산군 덕산면 경계",
    peak: null,
    latitude: 36.7066013257,
    longitude: 126.60836041,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 36.557229,
    ccLng: 126.779757,
    cZoom: 8,
  },
  {
    rank: 97,
    sanname: "감악산",
    sanheight: 674.9,
    province: "경기도",
    address: "경기도 파주시 적성면ㆍ 양주시 남면ㆍ연천군 전곡읍",
    peak: null,
    latitude: 37.94111345,
    longitude: 126.9700241,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.7,
    ccLng: 127.046277,
    cZoom: 9,
  },
  {
    rank: 98,
    sanname: "삼악산",
    sanheight: 655.8,
    province: "강원도",
    address: "강원도 춘천시 서면",
    peak: "용화봉",
    latitude: 37.83969167,
    longitude: 127.6604028,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
  {
    rank: 99,
    sanname: "수락산",
    sanheight: 638,
    province: "서울특별시",
    address: "서울특별시 노원구, 경기도 남양주ㆍ의정부시",
    peak: "주봉",
    latitude: 37.69922309,
    longitude: 127.0813438,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 37.5642135,
    ccLng: 127.0016985,
    cZoom: 9,
  },
  {
    rank: 100,
    sanname: "관악산",
    sanheight: 632.2,
    province: "서울특별시",
    address: "서울특별시 관악구, 경기도 안양시ㆍ 과천시",
    peak: null,
    latitude: 37.44507652,
    longitude: 126.9640911,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.5642135,
    ccLng: 127.0016985,
    cZoom: 9,
  },
  {
    rank: 101,
    sanname: "축령산(장성)",
    sanheight: 621,
    province: "전라남도",
    address: "전라남도 장성군 서남면, 북일면",
    peak: null,
    latitude: 35.37095833,
    longitude: 126.7260417,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 34.8194,
    ccLng: 126.893113,
    cZoom: 8,
  },
  {
    rank: 102,
    sanname: "청계산",
    sanheight: 620,
    province: "서울특별시",
    address: "서울특별시 서초구,경기도 과천시ㆍ성남시ㆍ의왕시",
    peak: "매봉",
    latitude: 37.42799808,
    longitude: 127.0435783,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 37.5642135,
    ccLng: 127.0016985,
    cZoom: 9,
  },
  {
    rank: 103,
    sanname: "팔영산",
    sanheight: 606.9,
    province: "전라남도",
    address: "전라남도 고흥군 점암면ㆍ영남면",
    peak: "깃대봉",
    latitude: 34.61715278,
    longitude: 127.4364528,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 34.8194,
    ccLng: 126.893113,
    cZoom: 8,
  },
  {
    rank: 104,
    sanname: "소요산",
    sanheight: 587.5,
    province: "경기도",
    address: "경기도 동두천시, 포천시 신북면",
    peak: "의상대",
    latitude: 37.93851111,
    longitude: 127.0879417,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.7,
    ccLng: 127.046277,
    cZoom: 9,
  },
  {
    rank: 105,
    sanname: "강천산 ",
    sanheight: 583.7,
    province: "전라북도",
    address: "전라북도 순창군 팔덕면, 전라남도 담양군 용면",
    peak: null,
    latitude: 35.404278,
    longitude: 127.049024,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 35.716705,
    ccLng: 127.144185,
    cZoom: 8,
  },
  {
    rank: 106,
    sanname: "칠갑산",
    sanheight: 559.7,
    province: "충청남도",
    address: "충청남도 청양군 대치면, 정산면, 장평면",
    peak: null,
    latitude: 36.41303454,
    longitude: 126.8849185,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 36.557229,
    ccLng: 126.779757,
    cZoom: 8,
  },
  {
    rank: 107,
    sanname: "연화산",
    sanheight: 524,
    province: "경상남도",
    address: "경상남도 고성군 개천면ㆍ영현면",
    peak: null,
    latitude: 35.070937,
    longitude: 128.265077,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 35.259787,
    ccLng: 128.664734,
    cZoom: 8,
  },
  {
    rank: 108,
    sanname: "불갑산",
    sanheight: 518,
    province: "전라남도",
    address: "전남 함평군 해보면, 영광군 불갑면",
    peak: "연실봉",
    latitude: 35.19074444,
    longitude: 126.5649944,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 34.8194,
    ccLng: 126.893113,
    cZoom: 8,
  },
  {
    rank: 109,
    sanname: "덕숭산(수덕산)",
    sanheight: 495.2,
    province: "충청남도",
    address: "충청남도 예산군 덕산면",
    peak: null,
    latitude: 36.671723,
    longitude: 126.618118,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 36.557229,
    ccLng: 126.779757,
    cZoom: 8,
  },
  {
    rank: 110,
    sanname: "남산(금오산)",
    sanheight: 495.1,
    province: "경상북도",
    address: "경상북도 경주시 남산동, 내남면",
    peak: "금오봉",
    latitude: 35.78884618,
    longitude: 129.2228758,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 36.248647,
    ccLng: 128.664734,
    cZoom: 9,
  },
  {
    rank: 111,
    sanname: "달마산",
    sanheight: 489,
    province: "전라남도",
    address: "전라남도 해남군 송지면 미황사길 164",
    peak: "달마봉",
    latitude: 34.38257778,
    longitude: 126.5851639,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 34.8194,
    ccLng: 126.893113,
    cZoom: 8,
  },
  {
    rank: 112,
    sanname: "삼성산",
    sanheight: 480.9,
    province: "서울특별시",
    address: "서울특별시 관악구ㆍ동작구ㆍ금천구, 경기도 안양시",
    peak: null,
    latitude: 37.4359,
    longitude: 126.93936,
    n_park: false,
    san100: false,
    black100: false,
    ccLat: 37.5642135,
    ccLng: 127.0016985,
    cZoom: 9,
  },
  {
    rank: 113,
    sanname: "마니산",
    sanheight: 472.1,
    province: "인천광역시",
    address: "인천광역시 강화군 화도면",
    peak: null,
    latitude: 37.61558695,
    longitude: 126.430707,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.469221,
    ccLng: 126.573234,
    cZoom: 10,
  },
  {
    rank: 114,
    sanname: "변산",
    sanheight: 459,
    province: "전라북도",
    address: "전라북도 부안군 변산면, 상서면, 진서면",
    peak: null,
    latitude: 35.674087,
    longitude: 126.598889,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 35.716705,
    ccLng: 127.144185,
    cZoom: 8,
  },
  {
    rank: 115,
    sanname: "미륵산 ",
    sanheight: 458.4,
    province: "경상남도",
    address: "경상남도 통영시 산양읍ㆍ봉평동",
    peak: null,
    latitude: 34.811347,
    longitude: 128.416264,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 35.259787,
    ccLng: 128.664734,
    cZoom: 8,
  },
  {
    rank: 116,
    sanname: "덕룡산",
    sanheight: 432.9,
    province: "전라남도",
    address: "전라남도 강진군 도암면, 신전면 4.",
    peak: "서봉",
    latitude: 34.53853333,
    longitude: 126.6991944,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 34.8194,
    ccLng: 126.893113,
    cZoom: 8,
  },
  {
    rank: 117,
    sanname: "내변산",
    sanheight: 424,
    province: "전라북도",
    address: "전라북도 부안군 변산면, 상서면, 진서면",
    peak: "관음봉",
    latitude: 35.62316667,
    longitude: 126.5824444,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 35.716705,
    ccLng: 127.144185,
    cZoom: 8,
  },
  {
    rank: 118,
    sanname: "지리산",
    sanheight: 399.3,
    province: "경상남도",
    address: "경상남도 통영시 사량도 사량면",
    peak: "지이망산",
    latitude: 34.851541,
    longitude: 128.196924,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 35.259787,
    ccLng: 128.664734,
    cZoom: 8,
  },
  {
    rank: 119,
    sanname: "용봉산",
    sanheight: 381,
    province: "충청남도",
    address: "강원도 홍성군 홍북읍, 예산군 덕산면",
    peak: null,
    latitude: 36.64365278,
    longitude: 126.6491972,
    n_park: false,
    san100: false,
    black100: true,
    ccLat: 36.557229,
    ccLng: 126.779757,
    cZoom: 8,
  },
  {
    rank: 120,
    sanname: "깃대봉",
    sanheight: 360.7,
    province: "전라남도",
    address: "전라남도 신안군 흑산면 홍도",
    peak: null,
    latitude: 34.695721,
    longitude: 125.202164,
    n_park: false,
    san100: true,
    black100: false,
    ccLat: 34.8194,
    ccLng: 126.893113,
    cZoom: 8,
  },
  {
    rank: 121,
    sanname: "아차산",
    sanheight: 348,
    province: "서울특별시",
    address: "서울특별시 광진구ㆍ중랑구, 경기도 구리시",
    peak: null,
    latitude: 37.570705,
    longitude: 127.10392,
    n_park: false,
    san100: false,
    black100: false,
    ccLat: 37.5642135,
    ccLng: 127.0016985,
    cZoom: 9,
  },
  {
    rank: 122,
    sanname: "인왕산",
    sanheight: 338.2,
    province: "서울특별시",
    address: "서울특별시 종로구ㆍ서대문구",
    peak: null,
    latitude: 37.584854,
    longitude: 126.958694,
    n_park: false,
    san100: false,
    black100: false,
    ccLat: 37.5642135,
    ccLng: 127.0016985,
    cZoom: 9,
  },
  {
    rank: 123,
    sanname: "선운산",
    sanheight: 334.7,
    province: "전라북도",
    address: "전라북도 고창군 아산면ㆍ심원면ㆍ해리면",
    peak: "수리봉",
    latitude: 35.498675,
    longitude: 126.5689861,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 35.716705,
    ccLng: 127.144185,
    cZoom: 8,
  },
  {
    rank: 124,
    sanname: "팔봉산",
    sanheight: 328.2,
    province: "강원도",
    address: "강원도 홍천군 서면",
    peak: "2봉",
    latitude: 37.6959326,
    longitude: 127.697033,
    n_park: false,
    san100: true,
    black100: true,
    ccLat: 37.83041,
    ccLng: 128.2260705,
    cZoom: 8,
  },
];

export default function getSans() {
  return sans;
}
