import React from "react";
import BottomNav from "../bottomNav/BottomNav";
import "./header.css";

const Header = ({ sanname }) => {
  return (
    <div className="header__container">
        <div className="mountain_title">
          <h2>{sanname}</h2>
        </div>
        <BottomNav sanname={sanname} />
    </div>
  );
};

export default Header;
