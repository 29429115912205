import React from "react";
import "./mainImages.css";
import leftArrow from "./left-arrow.svg";
import rightArrow from "./right-arrow.svg";

const BtnSlider = ({ direction, moveSlide }) => {
  //   console.log(direction, moveSlide);
  return (
    <button
      onClick={moveSlide}
      className={direction === "next" ? "btn__slide next" : "btn__slide prev"}
    >
      <img src={direction === "next" ? rightArrow : leftArrow} width={20} alt='arrow'/>
    </button>
  );
  //   { direction, moveSlide };
};
export default BtnSlider;
