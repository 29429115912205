import React from "react";
import { Link } from "react-router-dom";
import "./notFound.css";

const NotFound = () => {
  return (
    <>
      <div className="not_container">
        <h1>NotFound</h1>
      </div>
      <div>
        <Link to={"/"}>
          <button>홈으로</button>
        </Link>
      </div>
    </>
  );
};

export default NotFound;
