import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const Footer = () => {
  return (
    <>
      <div className="footer__container">
        <div className="waves">
          <div className="wave" id="wave1"></div>
          <div className="wave" id="wave2"></div>
          <div className="wave" id="wave3"></div>
          <div className="wave" id="wave4"></div>
        </div>
        {/* Footer Links */}
        <div className="footer__lists">
          <div className="footer__list__left">
            <ul>
              <li>
                <a
                  href="https://www.weather.go.kr/w/theme/mountain-weather.do"
                  target="_blank"
                  rel="noreferrer"
                >
                  모든산악날씨
                </a>
              </li>
              <li>
                <a
                  href="https://www.knps.or.kr/portal/main.do"
                  target="_blank"
                  rel="noreferrer"
                >
                  한국국립공원
                </a>
              </li>
              <li>
                <a
                  href="https://reservation.knps.or.kr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  국립공원예약시스템
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__list__right">
            <ul>
              {/* <li>
                <a
                  href="https://map.kakao.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  카카오지도
                </a>
              </li> */}
              <li>
                <Link to="/geoposition" className="current__position">
                  현재위치 좌표
                </Link>
              </li>
              <li>
                <a
                  href="https://google.co.kr/maps/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Google Map
                </a>
              </li>
              <li>
                <a
                  href="https://map.naver.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  네이버지도-현위치
                </a>
              </li>
              {/* <li>
              <a
                href="https://www.data.go.kr/"
                target="_blank"
                rel="noreferrer"
              >
                공공데이터포털
              </a>
            </li> */}
            </ul>
          </div>
        </div>
        {/* 연락처 */}
        <div className="footer__box">
          <span className="copyright">
            ©2022 www.2ok.kr | All Rights Reserved
          </span>
          <span>
            <a href="mailto:syngyang@gmail.com" className="mailto">
              <MailOutlineIcon
                sx={{ fontSize: "0.9rem", marginRight: "2px" }}
              />
              contact
            </a>
          </span>
        </div>
      </div>
    </>
  );
};

export default Footer;
