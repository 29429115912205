import React from "react";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import "./commentForm.css";

const CommentEditForm = ({
  // newComment,
  edittedComment,
  setEdittedComment,
  handleCommentEditSubmit,
}) => {
  // const isTextareaDisable = edittedComment.comment.length === 0;
  return (
    <div className="comment__form__container">
      <div className="comment__form">
        <form
          className="form__self"
          onSubmit={(e) => handleCommentEditSubmit(e)}
        >
          <input
            className="comment__input"
            type="text"
            //   maxLength="100"
            value={edittedComment.comment}
            onChange={(e) => {
              setEdittedComment({ ...edittedComment, comment: e.target.value });
            }}
            placeholder="댓글수정 ..."
          />
          {edittedComment && (
            <Button
              variant="outlined"
              color="secondary"
              type="submit"
              // disabled={isTextareaDisable}
            >
              <SendIcon sx={{}} />
            </Button>
          )}
        </form>
      </div>
    </div>
  );
};

export default CommentEditForm;
