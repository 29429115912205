import React, { useEffect } from "react";
import useStorage from "../../hooks/useStorage";

const ProgressBar = ({
  gatePic,
  setGatePic,
  sanCardId,
  sanname,
  setIsPromoModalOpen,
}) => {
  const { picUrl, progress } = useStorage(gatePic, sanname, sanCardId);

  useEffect(() => {
    if (picUrl) {
      setGatePic(null);
      setIsPromoModalOpen(false);
    }
    // eslint-disable-next-line
  }, [picUrl, setGatePic]);
  // if (percentage < 100) {
  //   setLoading(true);
  // } else {
  //   setLoading(false);
  // }
  return (
    <div
      className="progressbar"
      style={{ width: progress + "%", height: "6px", background: "crimson" }}
    ></div>
  );
};

export default ProgressBar;
