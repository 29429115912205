import React, { useState } from "react";
import "./mainImages.css";
import BtnSlider from "./BtnSlider";
import { useUserContext } from "../../context/userContext";
import { doc, updateDoc, arrayRemove } from "firebase/firestore";
import { db } from "../../firebase/index";
// import dataSlider from "../../data/dataSlider";

const MainImages = ({ gates, sanname, sanCardId }) => {
  const { user } = useUserContext();
  // console.log(gates.length, gates[0].picUrl, sanname, sanCardId);
  const [slideIndex, setSlideIndex] = useState(1);
  const nextSlide = () => {
    if (slideIndex !== gates.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === gates.length) {
      setSlideIndex(1);
    }
  };
  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(gates.length);
    }
  };
  const moveDot = (index) => {
    setSlideIndex(index);
  };
  const handleImageDelete = async (gate) => {
    if (window.confirm("삭제 하시겠습니까?")) {
      try {
        const collectionRef = doc(db, sanname, sanCardId);
        await updateDoc(collectionRef, {
          gateImage: arrayRemove(gate),
        });
        // deleteDoc(docRef);
        // toast("삭제되었습니다", {type:"success"})
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <>
      <div className="mainimage__container">
        <div className="container__slider">
          {gates.map((gate, index) => {
            return (
              <div
                key={index}
                className={
                  slideIndex === index + 1 ? "slide active__anim" : "slide"
                }
              >
                {user.displayName === gate.uploader && (
                  <span
                    className="delete_x"
                    onClick={() => handleImageDelete(gate)}
                  >
                    &times;
                  </span>
                )}
                <img src={gate.picUrl} />
                <div className="image__loader">©{gate.uploader}</div>
              </div>
            );
          })}
          {gates.length >= 2 && (
            <BtnSlider moveSlide={nextSlide} direction={"next"} />
          )}
          {gates.length >= 2 && (
            <BtnSlider moveSlide={prevSlide} direction={"prev"} />
          )}

          <div className="container-dots">
            {gates.map((gate, index) => (
              <div
                key={index}
                onClick={() => moveDot(index + 1)}
                className={slideIndex === index + 1 ? "dot active" : "dot"}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainImages;
