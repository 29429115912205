import React from "react";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import "./replyForm.css";

const ReplyForm = ({ handleReplySubmit, setReplyText, replyText }) => {
  return (
    <div className="reply__form__container">
      <div className="reply__form">
        <form className="form__self" onSubmit={handleReplySubmit}>
          <input
            // ref={replyInputRef}
            className="reply__input"
            type="text"
            maxLength="100"
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
            placeholder=" 댓글 회신"
          />

          {replyText.length > 0 && (
            <Button variant="outlined" color="primary" type="submit">
              <SendIcon />
            </Button>
          )}
        </form>
      </div>
    </div>
  );
};

export default ReplyForm;
