import React from "react";
import "./categories.css";

const Categories = ({ uniqueProvinces, filterItems }) => {
  return (
    <div className="cate__box">
      <label htmlFor="province"></label>
      <select
        id="province"
        className="select_box"
        onChange={(e) => filterItems(e.target.value)}
      >
        {uniqueProvinces.map((province, index) => {
          return (
            <option className="option_box" value={province} key={index}>
              {province}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Categories;
