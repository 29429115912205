import { useState, useEffect } from "react";
import { storage, db } from "../firebase";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useUserContext } from "../context/userContext";

const useStorage = (gatePic, sanname, sanCardId) => {
  const [progress, setProgress] = useState(0);
  const [picUrl, setPicUrl] = useState(null);
  const { user, error, setError } = useUserContext();
  // console.log("useStorage ... ");

  useEffect(() => {
    const gateStorageRef = ref(storage, `gate/${gatePic.name}`);
    const gateUrlRef = doc(db, sanname, sanCardId);

    const uploadTask = uploadBytesResumable(gateStorageRef, gatePic);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        let percentage = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(percentage);
      },
      (err) => {
        setError("업로딩이 되지 않았습니다");
      },

      async () => {
        // const gateId = uuid4();
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        updateDoc(gateUrlRef, {
          gateImage: arrayUnion({
            // gateId: gateId,
            picUrl: downloadURL,
            uploader: user.displayName,
            // gateImage: arrayUnion({
          }),
        });

        setPicUrl(downloadURL);

        // console.log("getDownloadURL ...", downloadURL);
      }
    );
    // eslint-disable-next-line
  }, [gatePic]);
  return { progress, picUrl, error };
};

export default useStorage;
