import React from "react";
import "./comment.css";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { doc, updateDoc, arrayRemove } from "firebase/firestore";
import { db } from "../../firebase/index";
import { useUserContext } from "../../context/userContext";
import CommentReply from "../commentReply/CommentReply";

const Comment = ({
  sanCardId,
  rootComment,
  // isReply,
  fetchedComments,
  handleRepyIcon,
  // setActiveComment,
  handleCommentEditIcon,
}) => {
  const { user } = useUserContext();
  const canReply = Boolean(
    user
  );
  // console.log("rootComment.commenter:",rootComment.commenter,"user.displayName :",user.displayName)
  const canEdit = Boolean(user.displayName === rootComment.commenter);

  const getReplies = (id) => {
    return fetchedComments
      .filter((fetched) => id === fetched.parentId)
      .sort(
        (a, b) =>
          new Date(a.commentDate).getTime() - new Date(b.commentDate).getTime()
      );
  };
  const replies = getReplies(rootComment.commentId);
// console.log("replies",replies)
  const handleCommentDelete = async (comment) => {
    if (window.confirm("삭제 하시겠습니까?")) {
      try {
        const commentRef = doc(db, rootComment.sanname, sanCardId);
        await updateDoc(commentRef, {
          comments: arrayRemove(comment),
        });
        // deleteDoc(docRef);
        // toast("삭제되었습니다", {type:"success"})
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <>
      <div
        key={rootComment.commentId}
        className="comment__container"
        >
        <div className="comment__avatar">
          <img src="/images/img_avatar.png" width={35} alt="avarta"/>
        </div>
        <div className="comment__content">
          <div className="comment__content__upper">
            <div className="comment__content__upper__x">
              <span>{rootComment.comment}</span>
            {user.uid === rootComment.commenterId && (
              <CancelOutlinedIcon 
                onClick={() => handleCommentDelete(rootComment)}
                className="comment__delete_x"
                fontSize='small'
                color='error'
              />
            )}
            </div>
          </div>
          <div className="comment__content__bottom">
            <div className="comment__commenter">{rootComment.commenter}</div>
            <div className="comment__bottom_right">
              {canReply && (
                  <span className="comment__reply"  onClick={() => handleRepyIcon(rootComment.commentId)}>
                    회신
                  </span>
              )}
              {canEdit && (
                  <span className="comment__edit" onClick={() => handleCommentEditIcon(rootComment.commentId)}>
                    수정
                  </span>
              )}
              <span className='comment__date'>{rootComment.commentDate.toDate().toLocaleString()}</span>
            </div>
          </div>
        </div>
      </div>
     {replies.length > 0 && (
      <div className="replies">
        {replies.map((reply) => (
          <CommentReply
            reply={reply}
            sanCardId={sanCardId}
            key={reply.commentId}
            replies={[]}
            rootComment={rootComment}
            parentId={rootComment.commentId}
          />
        ))}
      </div>
      )}
    </>
  );
};

export default Comment;

// <span
                //   className="delete_x"
                //   onClick={() => handleCommentDelete(rootComment)}
                // >
                //   &times;
                // </span>
