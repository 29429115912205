import React from "react";
import { Link } from "react-router-dom";
import "./san.css";

const San = ({ sanItems }) => {
  return (
    <div>
      <div className="san__section">
        {sanItems.map((sanItem) => {
          const {
            rank,
            sanname,
            // img,
            // province,
            sanheight,
            n_park,
            san100,
            address,
            black100,
          } = sanItem;
          return (
            <article key={rank} className="san__box">
              <div className="san__info">
                <div className="info__upper">
                  <header className="san__title">
                    <h4>
                      {sanname}
                      <span className="rank">({rank})</span>
                      <span className="n_park">
                        {n_park ? (
                          <img src="../images/flag640_p.png" alt="flag" />
                        ) : (
                          ""
                        )}
                      </span>
                      <span className="n_park">
                        {san100 ? (
                          <img src="../images/100_2.png" alt="flag" />
                        ) : (
                          ""
                        )}
                      </span>
                      <span className="n_park">
                        {black100 ? (
                          <img src="../images/black100.png" alt="flag" />
                        ) : (
                          ""
                        )}
                      </span>
                    </h4>
                    <h4 className="high">{`${sanheight}m`}</h4>
                  </header>
                  <p className="san__address">{address}</p>
                </div>
                <div className="info__bottom">
                  <Link to={`/mountains/${sanname}`}>
                    <button
                      style={{ padding: "3px" }}
                      type="button"
                      className="san__btn"
                    >
                      산행정보
                    </button>
                  </Link>
                </div>
              </div>
            </article>
          );
        })}
      </div>
 {/* 하단의 색인 리스트 */}
      <div>
          <span className="saein">
            <img
              style={{ width: "20px" }}
              src="../images/flag640_p.png"
              alt="flag"
            />
            : 국립공원
          </span>
          <span className="saein">
            <img
              style={{ width: "20px" }}
              src="../images/100_2.png"
              alt="산림청100"
            />{" "}
            : 산림청
          </span>
          <span className="saein">
            <img
              style={{ width: "20px" }}
              src="../images/black100.png"
              alt="블랙야크100"
            />{" "}
            : 블랙야크 100대 명산
          </span>
      </div>
    </div>
  );
};

export default San;
