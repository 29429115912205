import React from "react";
import "./emptyPost.css";

const EmptyPost = () => {
  return (
    <div className="empty__post__container">
      <div className="empty__gongji__box">
        <div className="empty__header">
          <h4 className="empty__h4">첫 공지의 주인공이 되어 주세요</h4>
        </div>
        <p className="eric">
          <span>가장 강한 종(種, species)이 살아남는 것이 아니다. 지적 능력이 뛰어난 종도 아니다.</span>
          <span> 그저 변화에 가장 잘 적응하는 종인 것이다.</span>
          <span className="italic"> -Charles Darwin</span>
        </p>
      </div>
    </div>
  );
};

export default EmptyPost;
