import React, { useEffect, useState } from "react";
import "./mapSearch.css";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import {
  GoogleMap,
  // LoadScript,
  // useLoadScript,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import getSans from "../../data/san221113";
import seoul from "../../data/seoul.json";
import Categories from "../../components/categories/Categories";
import BoxInfo from "./BoxInfo";

const containerStyle = {
  width: "100%",
  height: "100vh",
};

const uniqueProvinces = [
  "지역선택",
  "서울특별시",
  "강원도",
  "경기도",
  "경상남도",
  "광주광역시",
  "대구광역시",
  "대전광역시",
  "부산광역시",
  "울산광역시",
  "인천광역시",
  "전라남도",
  "전라북도",
  "제주",
  "충청남도",
  "충청북도",
  "산림청100",
];
const MapSearch = () => {
  const [fetchedSans, setFetchedSans] = useState(null);
  const [provinceCenter, setProvinceCenter] = useState({
    lat: 35.95,
    lng: 128.25,
  });
  const [provinceZoom, setProvinceZoom] = useState(7);
  // 산아이템즈가 캐터고리별로 필터된 것임
  const [sanItems, setSanItems] = useState(seoul);
  const [selectedMarker, setSelectedMarker] = useState(null);

  useEffect(() => {
    const getSanDetails = getSans();
    setFetchedSans(getSanDetails);
    checkProvince(sanItems);
  }, [sanItems]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBRPR_Fj6uoXXB6mr6LcvLG8-uupktI7mo",
    // libraries: ["geometry", "drawings"],
  });
  const checkProvince = (province) => {
    if (province === "서울특별시") {
      setProvinceCenter({
        lat: 37.5642135,
        lng: 127.0016985,
      });
      setProvinceZoom(9);
      return;
    }
    if (province === "강원도") {
      setProvinceCenter({
        lat: 37.83041,
        lng: 128.2260705,
      });
      setProvinceZoom(8);
      return;
    }
    if (province === "경기도") {
      setProvinceCenter({
        lat: 37.7,
        lng: 127.046277,
      });
      setProvinceZoom(9);
      return;
    }
    if (province === "경상남도") {
      setProvinceCenter({
        lat: 35.259787,
        lng: 128.664734,
      });
      setProvinceZoom(8);
      return;
    }
    if (province === "경상북도") {
      setProvinceCenter({
        lat: 36.248647,
        lng: 128.664734,
      });
      setProvinceZoom(9);
      return;
    }
    if (province === "광주광역시") {
      setProvinceCenter({
        lat: 35.126033,
        lng: 126.831302,
      });
      setProvinceZoom(10);
      return;
    }
    if (province === "대구광역시") {
      setProvinceCenter({
        lat: 35.798838,
        lng: 128.583052,
      });
      setProvinceZoom(10);
      return;
    }
    if (province === "대전광역시") {
      setProvinceCenter({
        lat: 36.321655,
        lng: 127.178953,
      });
      setProvinceZoom(9);
      return;
    }
    if (province === "부산광역시") {
      setProvinceCenter({
        lat: 35.198362,
        lng: 129.053922,
      });
      setProvinceZoom(10);
      return;
    }
    if (province === "울산광역시") {
      setProvinceCenter({
        lat: 35.519301,
        lng: 129.139078,
      });
      setProvinceZoom(10);
      return;
    }
    if (province === "인천광역시") {
      setProvinceCenter({
        lat: 37.469221,
        lng: 126.573234,
      });
      setProvinceZoom(10);
      return;
    }
    if (province === "전라남도") {
      setProvinceCenter({
        lat: 34.8194,
        lng: 126.893113,
      });
      setProvinceZoom(8);
      return;
    }
    if (province === "전라북도") {
      setProvinceCenter({
        lat: 35.716705,
        lng: 127.144185,
      });
      setProvinceZoom(8);
      return;
    }
    if (province === "제주") {
      setProvinceCenter({
        lat: 33.364805,
        lng: 126.542671,
      });
      setProvinceZoom(10);
      return;
    }
    if (province === "충청남도") {
      setProvinceCenter({
        lat: 36.557229,
        lng: 126.779757,
      });
      setProvinceZoom(8);
      return;
    }
    if (province === "충청북도") {
      setProvinceCenter({
        lat: 36.628503,
        lng: 127.929344,
      });
      setProvinceZoom(8);
      return;
    }
    if (province === "산림청100") {
      setProvinceCenter({
        lat: 35.95,
        lng: 128.25,
      });
      setProvinceZoom(6.2);
      return;
    }
    return;
  };
  const filterItems = (province) => {
    checkProvince(province);
    if (province === "all") {
      // all은 스트링이므로 '' 잊지말것
      setSanItems(fetchedSans);
      return;
    }
    if (province === "산림청100") {
      const sanlim100 = fetchedSans.filter((san) => {
        return san.san100 === true;
      });
      setSanItems(sanlim100);
      return;
    }
    if (province === "블랙야크100") {
      const sanlim100 = fetchedSans.filter((san) => {
        return san.black100 === true;
      });
      setSanItems(sanlim100);
      return;
    }
    const newItems = fetchedSans.filter((san) => {
      return san.province === province;
    });
    setSanItems(newItems);
  };
  const handleClickMarker = (san) => {
    setSelectedMarker(san);
  };
  // console.log("selectedMarker", selectedMarker);
  return (
    <div className="map__search__container">
      <div className="map__category">
        <Categories
          uniqueProvinces={uniqueProvinces}
          filterItems={filterItems}
        />
      </div>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={provinceCenter}
          zoom={provinceZoom}
        >
          {sanItems.map((san) => {
            return (
              <Marker
                // animation={BOUNCE}
                key={san.rank}
                position={{
                  lat: san.latitude,
                  lng: san.longitude,
                }}
                onClick={() => {
                  handleClickMarker(san);
                }}
              />
            );
          })}
          {selectedMarker && (
            <InfoWindow
              position={{
                lat: selectedMarker.latitude + 0.1,
                lng: selectedMarker.longitude,
              }}
            >
              <h4>{selectedMarker.sanname}</h4>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
      <Link to="/">
        <div className="icon__box__map">
          <HomeOutlinedIcon
            sx={{ fontSize: "40px" }}
            className="home__icon__map"
          />
        </div>
      </Link>
      {selectedMarker && <BoxInfo sMarker={selectedMarker} />}
    </div>
  );
};
// MapSearch.defaultProps = {
//   center: {
//     lat: 35.95,
//     lng: 128.25,
//   },
//   zoom: 11,
// };
export default MapSearch;
