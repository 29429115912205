import React from "react";
import "./mapSearch.css";

const BoxInfo = ({ sMarker }) => {
  return (
    <div className="box__info__container">
      <>
        <div className="name">
          {sMarker.sanname}
          <span>{sMarker.peak === null ? "" : `- ${sMarker.peak}`}</span>
        </div>
        <div className="height">{sMarker.sanheight}m</div>
      </>
    </div>
  );
};

export default BoxInfo;
