import React, { useState, useEffect, createContext, useContext } from "react";
import {
  createUserWithEmailAndPassword,
  // updateProfile,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth, db } from "../firebase/index";
import { setDoc, doc, Timestamp, updateDoc } from "firebase/firestore";

export const UserContext = createContext({});

export const useUserContext = () => {
  return useContext(UserContext);
};

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState();
  const [error, setError] = useState("");
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onAuthStateChanged(auth, (res) => {
      res ? setUser(res) : setUser(null);
      setError("");
      setLoading(false);
      setIsSignupModalOpen(false);
      // if (res) console.log(res.auth.currentUser);
    });
    return unsubscribe;
  }, []);
  //res는 current user에 대한 것
  const registerUser = async (email, name, password) => {
    setLoading(true);
    try {
      // firebase는 이메일과 패스워드로 authentication을 하고 updateProfile로 displayName을 추가하지만, 여기서는 하지 않음
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      // Authentication 등록 후에 Firestore Database에 "users" 컬랙션으로 저장
      await setDoc(doc(db, "users", userCredential.user.uid), {
        uid: userCredential.user.uid,
        name,
        email,
        createdAt: Timestamp.fromDate(new Date()),
        isOnLine: true,
      });
      // const user = userCredential.user;
      // updateProfile(auth.currentUser, {
      //   displayName: name,
      // });
      // console.log(user);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const signInUser = async (email, password) => {
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      await updateDoc(doc(db, "users", userCredential.user.uid), {
        isOnLine: true,
      });
      // const user = userCredential.user;
      // console.log(user);
      setLoading(false);
      setIsLoginModalOpen(false);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }

    //   .catch((err) => setError(err.message))
    //   .finally(() => setLoading(false));
  };
  const logoutUser = async () => {
    {
      auth.currentUser &&
        (await updateDoc(doc(db, "users", auth.currentUser.uid), {
          isOnLine: false,
        }));
    }

    await signOut(auth);
  };
  const forgotPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };
  const googleSignIn = async () => {
    try {
      const googleAuthProvider = new GoogleAuthProvider();
      // setLoading(false);
      // setIsLoginModalOpen(false);
      return signInWithPopup(auth, googleAuthProvider);
    } catch (error) {
      setError("구글로그인 실패");
    }
  };
  const contextValue = {
    user,
    loading,
    error,
    isLoginModalOpen,
    isSignupModalOpen,
    setIsLoginModalOpen,
    setIsSignupModalOpen,
    setError,
    setLoading,
    registerUser,
    signInUser,
    logoutUser,
    forgotPassword,
    googleSignIn,
  };
  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserContextProvider;
