import React, { useState } from "react";
import "./newPost.css";
import {
  Stack,
  TextField,
  Grid,
  Button,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Box } from "@mui/system";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { db } from "../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useUserContext } from "../../context/userContext";
import { Navigate } from "react-router-dom";

const NewPost = ({ setIsNewPostOpen, sanname }) => {
  const { user } = useUserContext();
  const [postData, setPostData] = useState({});
  const [dateTime, setDateTime] = useState(null);
  const [kindy, setKindy] = useState("");
  const [course, setCourse] = useState("");
  const [entryPoint, setEntryPoint] = useState("");
  const [endPoint, setEndPoint] = useState("");
  const [diffy, setDiffy] = useState("");
  const [timmy, setTimmy] = useState("");
  const [dist, setDist] = useState("");
  const [fee, setFee] = useState("");
  const [courseDetail, setCourseDetail] = useState("");
  const leader = user.displayName;

  //useEffect제거
  const postNewPosting = async (postData) => {
    try {
      const collectionRef = collection(db, sanname);
      const docRef = await addDoc(collectionRef, postData);
      if (docRef)
        setIsNewPostOpen({
          type: false,
        });
    } catch (error) {
      console.log("Error", error);
      <Navigate to="/" />;
    }
  };

  const handleSanCardFormSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      comments: [],
      course,
      courseDetail,
      dateTime,
      diffy,
      dist,
      endPoint,
      entryPoint,
      fee,
      kindy,
      leader: user.displayName,
      like: [],
      gateImage: [],
      attendee: [],
      postDisplayName: user.displayName,
      postUid: user.uid,
      sanname,
      timeStamp: serverTimestamp(),
      timmy,
      writtenBy: user.email,
    };
    setPostData(postData);
    postNewPosting(postData);
  };

  const handleDateChange = (newValue) => {
    setDateTime(newValue);
  };
  const now = new Date();
  return (
    <>
      <Box>
        <form className="modal__newPosting" onSubmit={handleSanCardFormSubmit}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack mx={2}>
              <span
                className="close__newPosting"
                title="창 닫기"
                onClick={() => setIsNewPostOpen(false)}
              >
                &times;
              </span>

              <Grid container my={4} spacing={1}>
                <Grid item xs={12} sm={12}>
                  <Typography
                    sx={{ color: "primary.main" }}
                    align="center"
                    variant="h5"
                    gutterBottom
                  >
                    산행등록
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    label="출발 일 시"
                    value={dateTime}
                    minDate={new Date()}
                    maxDate={new Date(now.setDate(now.getDate() + 60))}
                    onChange={handleDateChange}
                    inputFormat="yyyy-MM-dd HH:mm"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ minWidth: 120 }} fullWidth>
                    <InputLabel id="demo-simple-select-helper-label">
                      유형
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={kindy}
                      label="유형"
                      onChange={(e) => setKindy(e.target.value)}
                    >
                      <MenuItem value={"워킹"}>워킹</MenuItem>
                      <MenuItem value={"암벽"}>암벽</MenuItem>
                      <MenuItem value={"트레일러닝"}>트레일러닝</MenuItem>
                      <MenuItem value={"캠핑"}>캠핑</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="코스"
                    variant="outlined"
                    value={course}
                    onChange={(e) => {
                      setCourse(e.target.value);
                    }}
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="outlined-basic"
                    label="리더"
                    defaultValue={leader}
                    variant="outlined"
                    // value = {leader}
                    // onChange={(e) => {
                    //   setLeader(e.target.value);
                    // }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="outlined-basic"
                    label="만남장소"
                    variant="outlined"
                    value={entryPoint}
                    onChange={(e) => {
                      setEntryPoint(e.target.value);
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="outlined-basic"
                    label="종료장소"
                    variant="outlined"
                    value={endPoint}
                    onChange={(e) => {
                      setEndPoint(e.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl sx={{ minWidth: 120 }} fullWidth>
                    <InputLabel id="demo-simple-select-helper-label">
                      난이도
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={diffy}
                      label="난이도"
                      onChange={(e) => setDiffy(e.target.value)}
                    >
                      <MenuItem value={"왕초보"}>왕초보</MenuItem>
                      <MenuItem value={"초보"}>초보</MenuItem>
                      <MenuItem value={"초급"}>초급</MenuItem>
                      <MenuItem value={"중급"}>중급</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl sx={{ minWidth: 100 }} fullWidth>
                    <InputLabel id="demo-dist-select-helper-label">
                      거리
                    </InputLabel>
                    <Select
                      labelId="demo-dist-select-helper-label"
                      id="demo-dist-select-helper"
                      label="거리"
                      value={dist}
                      onChange={(e) => setDist(e.target.value)}
                    >
                      <MenuItem value={"10km이하"}>10km이하</MenuItem>
                      <MenuItem value={"15km이하"}>15km이하</MenuItem>
                      <MenuItem value={"20km이하"}>20km이하</MenuItem>
                      <MenuItem value={"30km이하"}>30km이하</MenuItem>
                      <MenuItem value={"30km이상"}>30km이상</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl sx={{ minWidth: 100 }} fullWidth>
                    <InputLabel id="demo-timmy-select-helper-label">
                      소요시간
                    </InputLabel>
                    <Select
                      labelId="demo-timmy-select-helper-label"
                      id="demo-timmy-select-helper"
                      label="소요시간"
                      value={timmy}
                      onChange={(e) => setTimmy(e.target.value)}
                    >
                      <MenuItem value={"4시간이하"}>4시간이하</MenuItem>
                      <MenuItem value={"약5시간"}>약 5시간</MenuItem>
                      <MenuItem value={"약6시간"}>약 6시간</MenuItem>
                      <MenuItem value={"약7시간"}>약 7시간</MenuItem>
                      <MenuItem value={"8시간이상"}>8시간이상</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    id="outlined-basic"
                    type="number"
                    label="회비 - 원"
                    inputProps={{
                      step: 1000,
                      min: 1000,
                      // endAdornment: (
                      //   <InputAdornment min={1000} position="end">
                      //     원
                      //   </InputAdornment>
                      // ),
                    }}
                    variant="outlined"
                    value={fee}
                    size="large"
                    onChange={(e) => {
                      setFee(e.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    maxLength={4}
                    placeholder="코스설명"
                    color="primary"
                    multiline
                    rows={3}
                    value={courseDetail}
                    // size="lg"
                    fullWidth
                    onChange={(e) => setCourseDetail(e.target.value)}
                  />
                  {/* <Textarea
                    maxLength={5}
                    placeholder="코스설명"
                    color="primary"
                    minRows={3}
                    value={courseDetail}
                    size="lg"
                    onChange={(e) => setCourseDetail(e.target.value)}
                  /> */}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Stack direction="row" justifyContent="center" spacing={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      xs={6}
                      startIcon={<DeleteIcon />}
                      onClick={() => setIsNewPostOpen(false)}
                    >
                      취소
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      xs={6}
                      endIcon={<SendIcon />}
                    >
                      등록
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </LocalizationProvider>
        </form>
      </Box>
    </>
  );
};

export default NewPost;
