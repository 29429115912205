import React, { useRef, useState } from "react";
import "./login.css";
import { useUserContext } from "../../context/userContext";
import GoogleButton from "react-google-button";

const Login = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [message, setMessage] = useState("");

  const {
    setIsSignupModalOpen,
    setIsLoginModalOpen,
    signInUser,
    error,
    loading,
    setError,
    forgotPassword,
    googleSignIn,
  } = useUserContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    try {
      await signInUser(email, password);
      // console.log(user.displayName);
      // if (user) setIsLoginModalOpen(false);
    } catch (err) {
      // setInError(err);
      setError(err);
      console.log("Err", err);
    }
  };
  const forgotPasswordHandler = () => {
    const email = emailRef.current.value;
    if (email)
      forgotPassword(email).then(() => {
        emailRef.current.value = "";
        setMessage("보낸메일을 통해 수정하세요");
      });
  };
  const handleEnroll = () => {
    setIsLoginModalOpen(false);
    setIsSignupModalOpen(true);
  };
  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      setIsLoginModalOpen(false);
    } catch (error) {
      setError("구글로그인이 실패했습니다.");
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit} className="modal__content animate">
        <span
          className="close"
          title="Close Modal"
          onClick={() => setIsLoginModalOpen(false)}
        >
          &times;
        </span>
        <div className="container">
          {message && <p className="error_login">{message}</p>}
          {error && <p className="error_login">{error}</p>}

          <label>이메일</label>
          <input
            className="in__email"
            type="email"
            placeholder="이메일주소"
            ref={emailRef}
            name="uname"
            required
          />

          <label>비밀번호</label>
          <input
            className="in__password"
            type="password"
            placeholder="비밀번호"
            ref={passwordRef}
            name="psw"
            required
          />

          <button className="login__btn" type="submit" disabled={loading}>
            {loading ? "로그인 중..." : "로그인"}
          </button>

          <GoogleButton
            style={{ width: "100%" }}
            type="dark"
            className="google__button"
            onClick={handleGoogleSignIn}
          />
          {/* <label>
            <input type="checkbox" checked="checked" name="remember" /> 
            Remember me
          </label> */}
        </div>

        <div
          className="container__bottom"
          style={{ backgroundColor: "#f1f1f1" }}
        >
          <button type="button" onClick={handleEnroll} className="cancelbtn">
            등록
          </button>
          <p onClick={forgotPasswordHandler} className="forgot__p">
            비밀번호 재설정?
          </p>
        </div>
      </form>
      {/* </div> */}
    </>
  );
};

export default Login;
