import React, { useState, useEffect } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { db } from "../../firebase";
import {
  collection,
  // getDocs,
  deleteDoc,
  doc,
  query,
  // where,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import SanCard from "../sanCard/SanCard";
import EmptyPost from "../../pages/emptyPost/EmptyPost";
import "./posts.css";

const Posts = ({ sanname, handleNewPosting, handleSanCardUpdate }) => {
  const [fetchedPosts, setFetchedPosts] = useState([]);

  function deleteOutdated() {
    fetchedPosts.forEach((post) => {
      // console.log("post--", post.sanCardId);
      const today = new Date().getTime();
      const postDay = post.dateTime.seconds * 1000;
      if (postDay < today) {
        const docRef = doc(db, sanname, post.sanCardId);
        deleteDoc(docRef);
      }
    });
  }
// 지난 날짜의 포스트 서버에서 지우기
  deleteOutdated();
  
  useEffect(() => {
    const collectionRef = collection(db, sanname);
    const q = query(collectionRef, orderBy("dateTime", "asc"));
    const unsub = onSnapshot(q, (snapshot) => {
      const lists = [];
      snapshot.docs.forEach((card) => {
        lists.push({
          sanCardId: card.id,
          ...card.data(),
          viewing: true,
        });
        // console.log("sanCardId", card.id);
      });

      setFetchedPosts(lists);
    });
    return unsub;
    // eslint-disable-next-line
  }, []);

  return (
    <div className="posting__container">
    {/* 해당 산의 포스팅 내역 */}
      <div className="card__container">
        {!fetchedPosts.length > 0 ? (
          <EmptyPost />
        ) : (
          fetchedPosts.map((fetchedPost) => (
            <div key={fetchedPost.sanCardId}>
              <SanCard
                key={fetchedPost.id}
                fetchedPost={fetchedPost}
                handleSanCardUpdate={handleSanCardUpdate}
              />
            </div>
          ))
        )}
      </div>
    {/* 포스팅 추가 버튼 +  */}
      <div className="add__btn__box">
        <span className="posting__btn" onClick={handleNewPosting}>
          <AddCircleOutlineIcon className="add__icon" fontSize="large" />
        </span>
      </div>
    </div>
  );
};

export default Posts;

// const today = new Date().getTime();
  // const postDay = card.data().dateTime.seconds * 1000;
  // console.log(0 > postDay - today);
  // console.log(today, postDay);

  // if (0 > postDay - today) {
  //   deleteOutdated(card, sanname);
  //   console.log("deleteOutdated--");
  // } else {
  // console.log("deleteOutdated", sanname, i.data().sanCardId);
  // const docRef = doc(db, sanname, card.data().sanCardId);
  // console.log("deleteOutdated", sanname, i.sanCardId);
  //   deleteDoc(docRef);
  // }