import React from 'react'
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

function InfoSingle({fetchedPost}) {
  const da = new Date(fetchedPost.dateTime.seconds * 1000);
  const getDayOfWeek = (d) => {
    const week = ["일", "월", "화", "수", "목", "금", "토"];
    const dayOfWeek = week[new Date(da).getDay()];
    return dayOfWeek;
  };

  return (
    <>
    <div className='upper__detailed'>
      <Grid item xs={12} sm={12} py={1} align="center">
        <Typography sx={{ color: "black" }} variant="h7" gutterBottom>
          <span className="YY ssfirst">{`${da.getFullYear()}년`}</span>
          <span className="MM ssfirst">{`${da.getMonth() + 1}월`}</span>
          <span className="DD ssfirst">{`${da.getDate()}일`}</span>
          <span className="hh ssfirst">{`${da.getHours()}시`}</span>
          <span className="mm ssfirst">{`${da.getMinutes()}분`}</span>
          <span className="dd ssfirst">{`${getDayOfWeek(da)}요일`}</span>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} sx={{ paddingBottom: 2 }} align="center">
        <div className="course__name">{fetchedPost.course}</div>
      </Grid>
    </div>
    <div className='bottom__detailed'>
      <Grid sx={{ marginBottom: "5px" }}>
        <div className="sfirst">
          <span className="e__point">유 형 : {fetchedPost.kindy}</span>
        </div>
        <div className="sfirst">
          <span className="e__point">만남장소 : {fetchedPost.entryPoint}</span>
        </div>
        <div className="sfirst">
          <span>종료장소 : {fetchedPost.endPoint}</span>
        </div>
        <div className="sfirst">
          <span>리더 : {fetchedPost.leader}</span>
        </div>
      </Grid>
      <Grid>
        <div className="sfirst">
          <span className="t_diffy">{fetchedPost.diffy}</span>
          <span className="t_dist">{fetchedPost.dist}</span>
          <span className="t_timmy">{fetchedPost.timmy}</span>
          <span className="t_fee">{fetchedPost.fee}원</span>
        </div>
      </Grid>
      <Grid>
        <div className="single__courseDetail">{fetchedPost.courseDetail}</div>
      </Grid>
    </div>
    </>
  )
}

export default InfoSingle