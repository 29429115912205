import React from "react";
import "./bottomNav.css";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import LogoutIcon from "@mui/icons-material/Logout";

import { useUserContext } from "../../context/userContext";

const BottomNav = ({ sanname }) => {
  const {
    isLoginModalOpen,
    setIsLoginModalOpen,
    isSignupModalOpen,
    setIsSignupModalOpen,
    user,
    logoutUser,
  } = useUserContext();
  return (
    <div className="bottom__nav">
      <div className="nav__links">
        <div className="icon__box">
          <Link to="/">
            <HomeOutlinedIcon className="home__icon" />
          </Link>
        </div>
        <div className="bottom__nav__sanname">{sanname}</div>
        <div className="sign__div">
          {!user ? (
            <div
              className="login__modal login__box"
              onClick={() => setIsLoginModalOpen(!isLoginModalOpen)}
              >
              <LoginOutlinedIcon className="login__icon" />
              {/* 로그인 */}
            </div>
          ) : (
            <>
              <span className="display__name">{user.displayName}</span>
              <div className="logout__box" onClick={logoutUser}>
                <Link to="/">
                  <LogoutIcon className="logout__icon" />
                  {/* 로그아웃 */}
                </Link>
              </div>
            </>
          )}
          {/* bottomNav.css에서 display:none */}
          <div
            className="signup__modal signup__btn"
            onClick={() => setIsSignupModalOpen(!isSignupModalOpen)}
            >
            회원가입
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomNav;
