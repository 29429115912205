import React, { useRef } from "react";
// import React, { useState } from "react";
import "./signup.css";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useUserContext } from "../../context/userContext";

const inputStyle = {
  width: "100%",
  padding: " 12px 15px",
  margin: "8px 0",
  display: "inline-block",
  letterSpacing: "0.1rem",
  boxSizing: "border-box",
};

const Signup = () => {
  const emailRef = useRef();
  const nameRef = useRef();
  const passwordRef = useRef();
  // const avatarRef = useRef();
  // const [avatar, setAvatar] = useState();

  const { setIsSignupModalOpen, error, setError, loading, registerUser } =
    useUserContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = emailRef.current.value;
    const name = nameRef.current.value;
    const password = passwordRef.current.value;
    try {
      if (email && name && password) await registerUser(email, name, password);
    } catch (error) {
      setError(error);
    } finally {
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="modal__content animate">
        <div
          className="x__close"
          title="Close Modal"
          onClick={() => setIsSignupModalOpen(false)}
        >
          &times;
        </div>
        <div className="container">
          {error && <span className="error_span">{error}</span>}
          <div className="eInput">
            <label>이메일</label>
            <input
              style={inputStyle}
              className="up__email"
              type="email"
              placeholder="email"
              required
              ref={emailRef}
            />
            <label>이름</label>
            <input
              style={inputStyle}
              className="up__name"
              type="text"
              placeholder="별칭"
              required
              ref={nameRef}
            />

            <label>비밀번호</label>
            <input
              style={inputStyle}
              type="password"
              placeholder="6자 이상 입력"
              // name="psw"
              required
              ref={passwordRef}
            />
            {/* <label htmlFor="avatar" className="avatar__selection">
              <AccountCircleIcon />
              아바타 - 선택
            </label>
            <input
              style={{ display: "none" }}
              id="avatar"
              type="file"
              ref={avatarRef}
              onChange={(e) => setAvatar(e.target.files[0])}
            /> */}
          </div>

          <button className="login__btn" type="submit" disabled={loading}>
            {loading ? "등록 중 ..." : "등록"}
          </button>
          {/* <label>
            <input type="checkbox" checked="checked" name="remember" /> 
            Remember me
          </label> */}
        </div>
        <div
          className="container__bottom"
          style={{ backgroundColor: "#f1f1f1" }}
        >
          <button
            type="button"
            onClick={() => setIsSignupModalOpen(false)}
            className="cancelbtn"
          >
            취소
          </button>
        </div>
      </form>
    </>
  );
};

export default Signup;
