import React from "react";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import "./commentForm.css";

const CommentForm = ({
  // submitLabel,
  // isEdit,
  newComment,
  setNewComment,
  handleNewCommentSubmit,
}) => {
  // const isTextareaDisable = newComment.length === 0;
  return (
    <div className="comment__form__container">
      <div className="comment__form">
        <form className="form__self" onSubmit={handleNewCommentSubmit}>
          <input
            className="comment__input"
            type="text"
            maxLength="100"
            value={newComment}
            onChange={(e) => {
              setNewComment(e.target.value);
            }}
            placeholder="댓글 ..."
          />
          {newComment && (
            <Button
              variant="outlined"
              color="secondary"
              type="submit"
              // disabled={isTextareaDisable}
              >
              <SendIcon sx={{}} />
            </Button>
          )}
        </form>
      </div>
    </div>
  );
};

export default CommentForm;
