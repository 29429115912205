import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Header from "../../components/header/Header";
import Attendee from "../../components/attendee/Attendee";
import Promo from "../../promo/Promo";
import Login from "../login/Login";
import Signup from "../signup/Signup";
import MainImages from "../mainImages/MainImages";
import ReplyForm from "../../components/replyForm/ReplyForm";
import ReplyEditForm from "../../components/replyForm/ReplyEditForm";
import CommentForm from "../../components/commentForm/CommentForm";
import CommentEditForm from "../../components/commentForm/CommentEditForm";
import CommentList from "../../components/commentList/CommentList";
import { db } from "../../firebase/index";
// import MainGates from "../mainGates/MainGates";
import InfoSingle from './InfoSingle';
import "./singleCard.css";

import {
  arrayUnion,
  doc,
  query,
  updateDoc,
  onSnapshot,
  // addDoc, arrayRemove,collection,orderBy, where,setDoc,
} from "firebase/firestore";

import { useLocation } from "react-router-dom";
import { useUserContext } from "../../context/userContext";

// SanCard 의 전체정보버튼으로 부터 옴
const SingleCard = () => {
  const [newComment, setNewComment] = useState("");
  const [edittedComment, setEdittedComment] = useState({});
  const [restComment, setRestComment] = useState([]);
  const [replyText, setReplyText] = useState("");
  const [parent, setParent] = useState(null);
  const [activeComment, setActiveComment] = useState("");
  
  const [isComment, setIsComment] = useState(true);
  const [isCommentEdit, setIsCommentEdit] = useState(false);
  const [isPromoModalOpen, setIsPromoModalOpen] = useState(false);
  const [isReply, setIsReply] = useState(false);
  // const [isReplyEdit, setReplyEdit] = useState(false);
  const isReplyEdit= false;
  
  const [gates, setGates] = useState([]);
  const [fetchedComments, setFetchedComments] = useState([]);

  const { user, setIsLoginModalOpen, isLoginModalOpen, isSignupModalOpen } =
    useUserContext();
  const rootComments = fetchedComments
    .filter((comment) => comment.parentId === null)
    .reverse();

  let location = useLocation();
  const fetchedPost = location.state.fetchedPost;
  const {
    sanCardId,
    sanname,
    leader,
    // kindy,
    // course,
    // entryPoint,
    // endPoint,
    // diffy,
    // dist,
    // timmy,
    // fee,
    // courseDetail,
    // dateTime,
    postDisplayName,
    // attendees = [],
  } = fetchedPost;

  useEffect(() => {
    const docRef = doc(db, sanname, sanCardId);
    const q = query(docRef);
    const unsub = onSnapshot(q, async (snapshot) => {
      const fetched = await snapshot.data().comments;
      const fetchedGates = await snapshot.data().gateImage;
      setFetchedComments(fetched);
      setGates(fetchedGates);
    });
    return unsub;
    // eslint-disable-next-line
  }, []);

  const handleNewCommentSubmit = async (e) => {
    e.preventDefault();
    if (!newComment) return;
    if (user) {
      const commentId = uuidv4();
      const commentRef = doc(db, sanname, sanCardId);
      await updateDoc(commentRef, {
        comments: arrayUnion({
          sanname,
          replies: [],
          comment: newComment,
          commentId: commentId,
          parentId: null,
          postDisplayName,
          commentDate: new Date(),
          commenter: user.displayName,
          commenterId: user.uid,
        }),
      })
        .then(() => setNewComment(""))
        .catch((e) => console.log(e))
        .finally();
    } else {
      setNewComment("");
      setIsLoginModalOpen(true);
    }
  };

  const handleReplySubmit = async (e, parentId) => {
    e.preventDefault();
    // console.log("handleReplySubmit", parentId);
    if (user) {
      const replyId = uuidv4();
      const commentRef = doc(db, sanname, sanCardId);
      await updateDoc(commentRef, {
        comments: arrayUnion({
          sanname,
          comment: replyText,
          commentId: replyId,
          parentId: parent,
          postDisplayName,
          commentDate: new Date(),
          commenter: user.displayName,
        }),
      })
        .then(() => setReplyText(""))
        .then(() => setIsReply(false))
        .catch((e) => console.log(e))
        .finally();
    } else {
      setReplyText("");
      setIsLoginModalOpen(true);
    }
  };

  const handleRepyIcon = (id) => {
    setIsCommentEdit(false);
    setIsComment(false);
    setIsReply(true);
    setParent(id);
  };
  const handleCommentEditIcon = (id) => {
    setIsComment(false);
    setIsReply(false);
    setIsCommentEdit(true);
    // e.preventDefault();
    const filteredComment = fetchedComments.filter(
      (comment) => comment.commentId === id
    );
    const filtered = { ...filteredComment[0] };
    setEdittedComment(filtered);
    console.log("필터드", filtered);

    const restCom = fetchedComments.filter(
      (comment) => comment.commentId !== id
    );
    setRestComment(restCom);
  };
  const handleCommentEditSubmit = async (e) => {
    e.preventDefault();
    console.log(edittedComment);
    if (user) {
      // const commentId = uuidv4();
      const commentRef = doc(db, sanname, sanCardId);
      await updateDoc(commentRef, {
        comments: [...restComment, edittedComment],
      })
        .then(() => {
          setEdittedComment("");
          setIsCommentEdit(false);
          setIsComment(true);
        })
        .catch((e) => console.log(e))
        .finally();
    } else {
      //   setsetEdittedComment("");
      //   setIsLoginModalOpen(true);
    }
  };
  
  return (
  <div className="single__card__container">
    <Header sanname={sanname} />
    <div className="single__card__box">
      <InfoSingle fetchedPost={fetchedPost}/>
      <div className='promo__container'>
        {user?.displayName === leader && (
          <div className="promo">
            <span onClick={() => setIsPromoModalOpen(true)}>PromoCode</span>
          </div>
        )}
      </div>
      <div className='mainImage__container'>
        {gates.length > 0 && (
          <MainImages gates={gates} sanname={sanname} sanCardId={sanCardId} />
        )}
      </div>
      <div className='attendees__container'>
        <Attendee
          sanname={sanname}
          sanCardId={sanCardId}
        />
      </div>
      <div className='comment__list'>
        {user && (
          <CommentList
            sanCardId={sanCardId}
            rootComments={rootComments}
            isReply={isReply}
            fetchedComments={fetchedComments}
            activeComment={activeComment}
            handleRepyIcon={handleRepyIcon}
            setIsReply={setIsReply}
            setActiveComment={setActiveComment}
            handleCommentEditIcon={handleCommentEditIcon}
          />
        )}
      </div>
    </div>

    <div className="single__card__form">
    {isComment && (
      <CommentForm
        isCommentEdit={isCommentEdit}
        submitLabel="댓글"
        newComment={newComment}
        setNewComment={setNewComment}
        handleNewCommentSubmit={handleNewCommentSubmit}
      />
    )}

    {isReply && (
      <ReplyForm
        replyText={replyText}
        setReplyText={setReplyText}
        handleReplySubmit={handleReplySubmit}
      />
    )}

    {isCommentEdit && (
      <CommentEditForm
        isCommentEdit={isCommentEdit}
        newComment={newComment}
        edittedComment={edittedComment}
        setEdittedComment={setEdittedComment}
        // setEdittedComment={setEdittedComment}
        handleCommentEditSubmit={handleCommentEditSubmit}
      />
    )}
    {isReplyEdit && (
        <ReplyEditForm
          replyText={replyText}
          setReplyText={setReplyText}
          handleReplySubmit={handleReplySubmit}
        />
      )}
    </div>
{/* Modals */}
      {isLoginModalOpen && (
        <div className="modal">
          <div className="modal__box">
            <Login />
          </div>
        </div>
      )}

      {isSignupModalOpen && (
        <div className="modal">
          <div className="modal__box">
            <Signup />
          </div>
        </div>
      )}

      {isPromoModalOpen && (
        <div className="modal">
          <div className="modal__box">
            <Promo
              setIsPromoModalOpen={setIsPromoModalOpen}
              sanCardId={sanCardId}
              sanname={sanname}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleCard;
