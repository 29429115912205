import React from "react";
import Comment from "../comment/Comment";
import "./commentList.css";

const CommentList = ({
  sanCardId,
  rootComments,
  isReply,
  handleRepyIcon,
  setIsCommentReply,
  fetchedComments,
  activeComment,
  setActiveComment,
  handleCommentEditIcon,
  // isCommentReply,
}) => {
  return (
    <div className="comment__list__container">
        {rootComments.map((rootComment) => (
          // <div className="comment__list__box"> 
            <Comment
              key={rootComment.commentId} 
              sanCardId={sanCardId}
              rootComment={rootComment}
              isReply={isReply}
              handleRepyIcon={handleRepyIcon}
              fetchedComments={fetchedComments}
              setIsCommentReply={setIsCommentReply}
              setActiveComment={setActiveComment}
              activeComment={activeComment}
              handleCommentEditIcon={handleCommentEditIcon}
              // isCommentReply={isCommentReply}
            />
          // </div>
          ))}
    </div>    
  );
};

export default CommentList;
