import React, { useState } from "react";
import "./newPost.css";
import {
  Stack,
  TextField,
  Grid,
  Button,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Box } from "@mui/system";
import { useEffect } from "react";

const UpdatePost = ({ setIsNewPostOpen, sanname, sanCardId }) => {
  const [sanCardData, setSanCardData] = useState({});
  //   console.log("산카드데이터", sanCardData);
  const { dateTime } = sanCardData;

  useEffect(() => {
    const getUpdateDoc = async (sanCardId) => {
      const docRef = doc(db, sanname, sanCardId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setSanCardData({ ...docSnap.data() });
        // console.log("Document data:", docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    };
    getUpdateDoc(sanCardId);
    // eslint-disable-next-line
  }, []);
  const handleSanCardUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      // write to db
      await setDoc(doc(db, sanname, sanCardId), sanCardData);
      setIsNewPostOpen(false);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleDateChange = (newValue) => {
    setSanCardData({ ...sanCardData, dateTime: newValue });
  };
  const now = new Date();
  //   console.log("dateTime[0]", dateTime.toMillis());
  return (
    <>
      <Box>
        <form
          className="modal__newPosting"
          onSubmit={handleSanCardUpdateSubmit}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack mx={2}>
              <span
                className="close__newPosting"
                title="창 닫기"
                onClick={() => setIsNewPostOpen(false)}
              >
                &times;
              </span>

              <Grid container my={4} spacing={1}>
                <Grid item xs={12} sm={12}>
                  <Typography
                    sx={{ color: "primary.main" }}
                    align="center"
                    variant="h5"
                    gutterBottom
                  >
                    산행등록수정
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    label="출발 일 시"
                    value={dateTime ?? ""}
                    // sanCardData?.(+dateTime.seconds) * 1000 ?? null
                    minDate={new Date()}
                    maxDate={new Date(now.setDate(now.getDate() + 60))}
                    onChange={handleDateChange}
                    inputFormat="yyyy-MM-dd HH:mm"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ minWidth: 120 }} fullWidth>
                    <InputLabel id="demo-simple-select-helper-label">
                      유형
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="유형"
                      value={sanCardData?.kindy ?? ""}
                      onChange={(e) =>
                        setSanCardData({
                          ...sanCardData,
                          kindy: e.target.value,
                        })
                      }
                    >
                      <MenuItem value={"워킹"}>워킹</MenuItem>
                      <MenuItem value={"암벽"}>암벽</MenuItem>
                      <MenuItem value={"트레일러닝"}>트레일러닝</MenuItem>
                      <MenuItem value={"캠핑"}>캠핑</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="코스"
                    variant="outlined"
                    value={sanCardData?.course ?? ""}
                    // ref={courseRef}
                    onChange={(e) =>
                      setSanCardData({
                        ...sanCardData,
                        course: e.target.value,
                      })
                    }
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="outlined-basic"
                    label="리더"
                    // defaultValue={leader}
                    variant="outlined"
                    value={sanCardData?.leader ?? ""}
                    // onChange={(e) => {
                    //   setLeader(e.target.value);
                    // }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="outlined-basic"
                    label="만남장소"
                    variant="outlined"
                    value={sanCardData?.entryPoint ?? ""}
                    onChange={(e) =>
                      setSanCardData({
                        ...sanCardData,
                        entryPoint: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="outlined-basic"
                    label="종료장소"
                    variant="outlined"
                    value={sanCardData?.endPoint ?? ""}
                    onChange={(e) =>
                      setSanCardData({
                        ...sanCardData,
                        endPoint: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl sx={{ minWidth: 120 }} fullWidth>
                    <InputLabel id="demo-simple-select-helper-label">
                      난이도
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={sanCardData?.diffy ?? ""}
                      onChange={(e) =>
                        setSanCardData({
                          ...sanCardData,
                          diffy: e.target.value,
                        })
                      }
                      label="난이도"
                    >
                      <MenuItem value={"왕초보"}>왕초보</MenuItem>
                      <MenuItem value={"초보"}>초보</MenuItem>
                      <MenuItem value={"초급"}>초급</MenuItem>
                      <MenuItem value={"중급"}>중급</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl sx={{ minWidth: 100 }} fullWidth>
                    <InputLabel id="demo-dist-select-helper-label">
                      거리
                    </InputLabel>
                    <Select
                      labelId="demo-dist-select-helper-label"
                      id="demo-dist-select-helper"
                      label="거리"
                      value={sanCardData?.dist ?? ""}
                      onChange={(e) =>
                        setSanCardData({ ...sanCardData, dist: e.target.value })
                      }
                    >
                      <MenuItem value={"10km이하"}>10km이하</MenuItem>
                      <MenuItem value={"15km이하"}>15km이하</MenuItem>
                      <MenuItem value={"20km이하"}>20km이하</MenuItem>
                      <MenuItem value={"30km이하"}>30km이하</MenuItem>
                      <MenuItem value={"30km이상"}>30km이상</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl sx={{ minWidth: 100 }} fullWidth>
                    <InputLabel id="demo-timmy-select-helper-label">
                      소요시간
                    </InputLabel>
                    <Select
                      labelId="demo-timmy-select-helper-label"
                      id="demo-timmy-select-helper"
                      label="소요시간"
                      value={sanCardData?.timmy ?? ""}
                      onChange={(e) =>
                        setSanCardData({
                          ...sanCardData,
                          timmy: e.target.value,
                        })
                      }
                    >
                      <MenuItem value={"4시간이하"}>4시간이하</MenuItem>
                      <MenuItem value={"약5시간"}>약 5시간</MenuItem>
                      <MenuItem value={"약6시간"}>약 6시간</MenuItem>
                      <MenuItem value={"약7시간"}>약 7시간</MenuItem>
                      <MenuItem value={"8시간이상"}>8시간이상</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    id="outlined-basic"
                    type="number"
                    label="회비 - 원"
                    inputProps={{
                      step: 1000,
                      min: 1000,
                    }}
                    variant="outlined"
                    value={sanCardData?.fee ?? ""}
                    onChange={(e) => {
                      setSanCardData({ ...sanCardData, fee: e.target.value });
                    }}
                    size="large"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    maxLength={4}
                    placeholder="코스설명"
                    color="primary"
                    multiline
                    rows={3}
                    value={sanCardData?.courseDetail || null}
                    onChange={(e) => {
                      setSanCardData({
                        ...sanCardData,
                        courseDetail: e.target.value,
                      });
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Stack direction="row" justifyContent="center" spacing={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      xs={6}
                      startIcon={<DeleteIcon />}
                      onClick={() => setIsNewPostOpen(false)}
                    >
                      취소
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      xs={6}
                      endIcon={<SendIcon />}
                    >
                      수정
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </LocalizationProvider>
        </form>
      </Box>
    </>
  );
};

export default UpdatePost;
