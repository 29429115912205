import React from "react";
import "./topbar.css";
import { Link } from "react-router-dom";
import DarkModeOutlined from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';
const Topbar = ({ isDark, setIsDark }) => {

  return (
    <>
      <div className="topbar__container">
        <div className="topbar__wrapper">
          <div className="topbar__icon">
            {isDark ? (
              <LightModeOutlinedIcon onClick={() => setIsDark(!isDark)} />
            ) : (
              <DarkModeOutlined onClick={() => setIsDark(!isDark)} />
            )}
          </div>
          <div className="topbar__icon">
            <Link to="/mapsearch">
              <LocationSearchingOutlinedIcon sx={{ color: "white" }} />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Topbar;
