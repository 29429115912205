//san300 -2022-10-6
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// require("dotenv").config();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "san300-2da46.firebaseapp.com",
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: "san300-2da46.appspot.com",
  messagingSenderId: "810221787739",
  appId: "1:810221787739:web:6c1461cb0790c9374b3d7e",
  measurementId: "G-1HGJF7KPCQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
// export const { db, doc } = getFirestore(app);
export const storage = getStorage(app);
