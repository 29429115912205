import React from "react";
import {
  // addDoc,
  arrayRemove,
  arrayUnion,
  // collection,
  doc,
  // serverTimestamp,
  updateDoc,
  // setDoc,
} from "firebase/firestore";
import { db } from "../../firebase/index";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Badge } from "@mui/material";
import { useUserContext } from "../../context/userContext";
import "./likeArticle.css";
// id는 개별산카드의 id 이고, like은 서버의 숫자
const LikeArticle = ({ sanname, id, like }) => {
  const { user, setIsLoginModalOpen } = useUserContext();
  let likeRef = doc(db, sanname, id);

  const handleLike = async () => {
    if (user) {
      if (like?.includes(user.uid)) {
        await updateDoc(likeRef, {
          like: arrayRemove(user.uid),
        })
          .then(() => console.log("unlike"))
          .catch((e) => console.log(e));
      } else {
        updateDoc(likeRef, {
          like: arrayUnion(user.uid),
        })
          .then(() => console.log("like"))
          .catch((e) => console.log(e));
      }
    } else {
      setIsLoginModalOpen(true);
    }
  };
  return (
    <>
      <Badge
        badgeContent={Array.isArray(like) ? like.length : 0}
        max={50}
        color="secondary"
      >
        {/* {Array.isArray(like) ? ( */}
        {user && !like.includes(id) ? (
          <FavoriteIcon
            className={`card__favor__${
              !like?.includes(user.uid) ? "basic" : "icon"
            }`}
            onClick={handleLike}
          />
        ) : (
          <FavoriteBorderIcon
            className="card__favor__basic"
            onClick={handleLike}
          />
        )}
        {/* // null */}
      </Badge>
    </>
  );
};
export default LikeArticle;
