import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HistoryIcon from "@mui/icons-material/History";
import "./geoposition.css";
import useGeolocation from "react-hook-geolocation";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
// import dotenv from "dotenv";
// dotenv.config();

// const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API2;

// import GoogleMapReact from "google-map-react";
// import { Wrapper } from "@googlemaps/react-wrapper";
// const MyApp = () => (
//   <Wrapper apiKey={"AIzaSyAN19riKRW2vwLwEMb3F4cpiARGTMU9EOE"}>
//     <MyMapComponent />
//   </Wrapper>
// );
const Geoposition = () => {
  const geolocation = useGeolocation();
  const [showMap, setShowMap] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(null);

  const {} = useLoadScript({
    googleMapsApiKey: "AIzaSyBRPR_Fj6uoXXB6mr6LcvLG8-uupktI7mo",
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (data) {
      // console.log("position :", data);
      setCurrentPosition(data);
    });
  }, []);

  return currentPosition === null ? (
    <div>현재 위치데이터를 받을 수 없습니다</div>
  ) : (
    <>
      <div className="geoposition__container">
        <div className="geoposition__home__div">
          <Link to="/">
            <div className="home__icon__box">
              <HomeOutlinedIcon className="home__icon" />
            </div>
          </Link>
        </div>
        <div className="geoposition__back__div">
          <div className="back__icon__box">
            <HistoryIcon
              onClick={() => setShowMap(!showMap)}
              sx={{ color: "royalblue" }}
              className="back__icon"
            />
          </div>
        </div>

        {!showMap && (
          <div className="position_text_container">
            <div className="position_content">
              <h3>현재위치 좌표</h3>
              <ul>
                <li>
                  위도<span>(Lat)</span> :
                  {geolocation && Number(geolocation.latitude).toFixed(5)}
                </li>
                <li>
                  경도<span>(Lng)</span> :
                  {geolocation && Number(geolocation.longitude).toFixed(5)}
                </li>
                <li>
                  고도<span>(Alt)</span> : {geolocation.altitude}
                </li>
                <li>속도 : {geolocation.speed}</li>
                <li>방향 : {geolocation.heading}</li>
                {/* <li>시간: {geolocation.timestamp}</li> */}
                <li>위치정밀도 : {geolocation.accuracy}</li>
                <li>고도정밀도 : {geolocation.altitudeAccuracy}</li>
              </ul>
              <button
                style={{ padding: "10px" }}
                className="position__map__btn"
                onClick={() => setShowMap(true)}
              >
                지도표기
              </button>
            </div>
          </div>
        )}
        {showMap && (
          <>
            <GoogleMap
              zoom={15}
              center={{
                lat: currentPosition.coords.latitude,
                lng: currentPosition.coords.longitude,
              }}
              mapContainerClassName="map__container"
            >
              <Marker
                position={{
                  lat: currentPosition.coords.latitude,
                  lng: currentPosition.coords.longitude,
                }}
                name={"현위치"}
              />
            </GoogleMap>
          </>
        )}
      </div>
    </>
  );
};

export default Geoposition;
// // export default GoogleApiWrapper({
// //   apiKey: AIzaSyAN19riKRW2vwLwEMb3F4cpiARGTMU9EOE,
// // })(Geoposition);
