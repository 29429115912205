import React from "react";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import "./commentReply.css";
import { 
  doc, 
  updateDoc, 
  arrayRemove 
} from "firebase/firestore";
import { db } from "../../firebase/index";
import { useUserContext } from "../../context/userContext";

const CommentReply = ({ reply, rootComment, sanCardId }) => {
  const { user } = useUserContext();
  // const canReply = Boolean(user);
  // const canEdit = Boolean(user.displayName === rootComment.commenter);

  const handleCommentReplyDelete = async (reply) => {
    if (window.confirm("삭제 하시겠습니까?")) {
      try {
        const commentRef = doc(db, rootComment.sanname, sanCardId);
        await updateDoc(commentRef, {
          comments: arrayRemove(reply),
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  // const handleRepyIcon = ()=>{}
  // const handleReplyEditIcon = ()=>{}

  return (
    <div className="reply__container" style={{ marginTop: "5px" }}>
      <div className="reply__avatar__div">
        <img src="/images/img_avatar.png" width={30} alt='avarta'/>
      </div>
      <div className="reply__content">
        <div className="reply__content__upper__x">
          <span>{reply.comment}</span>
            {user.displayName === reply.commenter && (
          <CancelOutlinedIcon 
            onClick={() => handleCommentReplyDelete(reply)}
            className="comment__reply__delete_x"
            fontSize='small'
          />
            )}
        </div>
        <div className="reply__content__bottom">
          <div className="reply__commenter">{reply.commenter}</div>
          <div className="reply__bottom_right">
                {/* {canReply && (
              <span className="reply__reply"  onClick={() => handleRepyIcon(reply.replyId)}>
                회신
              </span>
                )}
                {canEdit && (
              <span className="reply__edit" onClick={() => handleReplyEditIcon(rootComment.commentId)}>
                수정
              </span>
                )} */}
              <span className='reply__date'>
                  {reply.commentDate.toDate().toLocaleDateString()}
                  {reply.commentDate.toDate().toLocaleTimeString()}
              </span>
          </div>
        </div>
      </div>
    </div>//1
          

  )
}

export default CommentReply;
 // rootComment.postDisplayName === user.displayName &&
    //   rootComment.commenter !== user.displayName