import React, { useState } from "react";
import "./notice.css";
import AddCommentIcon from "@mui/icons-material/AddComment";
import LanguageOutlined from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlined from "@mui/icons-material/DarkModeOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const Notice = ({ setIsNotice }) => {
  const [toggleTab, setToggleTab] = useState(1);
  const handleTab = (index) => {
    setToggleTab(index);
  };
  return (
    <div className="notice__container">
      <div className="modal__notice animate">
        <span
          className="close"
          title="Close Modal"
          onClick={() => setIsNotice(false)}
        >
          &times;
        </span>
        <div className="tab__container">
          <div className="tab__btns">
            <button
              className={toggleTab === 1 ? "tab active__tab" : "tab"}
              onClick={() => handleTab(1)}
            >
              산행등록
            </button>
            <button
              className={toggleTab === 2 ? "tab active__tab" : "tab"}
              onClick={() => handleTab(2)}
            >
              산행참여
            </button>
            <button
              className={toggleTab === 3 ? "tab active__tab" : "tab"}
              onClick={() => handleTab(3)}
            >
              사용팁
            </button>
          </div>
          <div className="tab__contents">
            <div
              className={toggleTab === 1 ? "content active__content" : "content"}
            >
              <div className="notice__upper">
                <ol>
                  <li>관련 산의 산행계획이 있는 누구나 등록이 가능합니다</li>
                  <li>해당 산과 관련된 공지만 할 수 있습니다</li>
                  <li>소규모 산행모임 공지 및 연락처 기재 가능합니다</li>
                  <li>영리, 안내산악회 스케줄도 공지 가능합니다</li>
                  <li>관련 산행계획, 안내 이외 등록은 불가합니다</li>
                  <li>
                    공지,댓글,하트등 엑션은 이메일과 이름(별칭)등록이 필수이며,
                    아바타는 선택입니다.
                  </li>
                  <li>
                    기간이 지난 공지는 삭제되며, 기록은 일체 보관하지 않습니다
                  </li>
                  <li>
                    Google 이메일이 있는 경우, 별도 등록없이 Google Button으로
                    인증할 수 있습니다.
                  </li>
                  <li>
                    Google 인증을 사용하므로 이메일, 비밀번호는 안전합니다.
                  </li>
                  <li>좋은 산행정보 제공처가 되도록 협조 부탁드립니다.</li>
                </ol>
              </div>
            </div>
            <div
              className={toggleTab === 2 ? "content active__content" : "content"}
            >
              <div className="notice__upper">
                <ol>
                  <li>공지된 산행은 누구나 열람 참여 가능합니다</li>
                  <li>
                    관련되신 모임의 공지인지 확인하시고, 참여를 권장합니다
                  </li>
                  <li>
                    현재 국립공원, 산림청100 및 블랙야크100명산 등 모든 산들은
                    높이 순서입니다
                  </li>
                  <li>
                    검색은 지역별로 가능하나, 여러지역에 겹치는 곳은 현재
                    대표지역 하나만 검색됩니다
                  </li>
                  <li>
                    표기된 회비는 리더의 재량으로 사용되며, 기타사항은 리더와
                    참여자의 협의하에 진행합니다
                  </li>
                  <li>
                    현재 124개의 산이 등재되었으며, 기타 자주 공지를 하는 곳이
                    있으면 올려드립니다.
                  </li>
                  {/* <li>감사합니다</li> */}
                </ol>
              </div>
            </div>
            <div
              className={toggleTab === 3 ? "content active__content" : "content"}
            >
              <div className="notice__upper">
                <ol>
                  <li>
                    <DarkModeOutlined
                      sx={{ color: "royalblue", fontSize: "18px" }}
                    />
                    Dark Mode는 메인페이지에만 해당됩니다.
                  </li>
                  <li>
                    <LanguageOutlined
                      sx={{ color: "royalblue", fontSize: "18px" }}
                    />
                    아이콘은 지도위에서 산 위치를 마커로 볼수 있으며, 지역을
                    선택하면 지역별로 출력되며, 마커아이콘을 클릭하면, 산이름과
                    높이가 우측 상단에 표기됩니다.(일부 핸드폰은 마커위에도
                    병기됩니다)
                  </li>
                  {/* <li>
                    <HomeOutlinedIcon
                      sx={{ color: "royalblue", fontSize: "18px" }}
                    />
                    아이콘은 메인페이지로 돌아갑니다.
                  </li> */}
                  <li>
                    메인페이지 하단에는 산행관련 링크들이 있으며, 현재 위치
                    좌표를 읽을 수 있는 링크가 있습니다. 상/우측의 아이콘은
                    텍스트/지도 토글아이콘입니다.
                  </li>
                  <li>
                    <AddCommentIcon
                      sx={{ color: "royalblue", fontSize: "18px" }}
                    />
                    은 댓글, 사진등을 추가/열람할 수 있는 링크로 이메일 인증이
                    필요입니다.
                  </li>
                  <li>
                    <FavoriteBorderIcon
                      sx={{ color: "red", fontSize: "18px" }}
                    />
                    아이콘(좋아요)은 인증후에 사용가능하며, 빨강으로 바뀐경우는
                    "좋아요"인증을 하신 것이며, 파랑색이 기본색입니다.
                  </li>
                  <li>
                    공지및 댓글을 올린 분의 경우는 관련글의 수정 및 삭제기능이
                    노출됩니다.
                  </li>
                  <li>감사합니다</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notice;
