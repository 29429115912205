import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Login from "../login/Login";
import Signup from "../signup/Signup";
import Posts from "../../components/posts/Posts";
import NewPost from "../../components/newPost/NewPost";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./mountain.css";
import { useUserContext } from "../../context/userContext";
import UpdatePost from "../../components/newPost/UpdatePost";

const Mountain = () => {
  const { sanname } = useParams();
  const [sanCardId, setSanCardId] = useState("");
  const [isNewPostOpen, setIsNewPostOpen] = useState({
    type: false,
    option: "new",
  });

  const { user, isLoginModalOpen, setIsLoginModalOpen, isSignupModalOpen } =
    useUserContext();
  // 로그인 확인 후 포스팅허용 또는 로그인(등록) 제안
  const handleNewPosting = () => {
    if (user) {
      setIsNewPostOpen({ type: true, option: "new" });
    } else {
      setIsLoginModalOpen(true);
    }
  };
  const handleSanCardUpdate = (id) => {
    if (user) {
      setIsNewPostOpen({ type: true, option: "edit" });
      setSanCardId(id);
    } else {
      setIsLoginModalOpen(true);
    }
  };
  return (
    <div className="m__container">
      <Header sanname={sanname} />
      <Posts
        sanname={sanname}
        handleNewPosting={handleNewPosting}
        handleSanCardUpdate={handleSanCardUpdate}
      />
      <Footer />
    {/* 로그인, 싸인업 모달  */}
      {isLoginModalOpen && (
        <div className="modal">
          <div className="modal__box">
            <Login />
          </div>
        </div>
      )}
      {isSignupModalOpen && (
        <div className="modal">
          <div className="modal__box">
            <Signup />
          </div>
        </div>
      )}
    {/* New Post Window 모달 */}
      {isNewPostOpen.type && isNewPostOpen.option === "new" && (
        <div className="modal">
          <div className="modal__box">
            <NewPost
              sanname={sanname}
              setIsNewPostOpen={setIsNewPostOpen}
              // handleSanCardUpdate={handleSanCardUpdate}
            />
          </div>
        </div>
      )}
    {/* Update Post Window 모달 */}
      {isNewPostOpen.type && isNewPostOpen.option === "edit" && (
        <div className="modal">
          <div className="modal__box">
            <UpdatePost
              sanname={sanname}
              sanCardId={sanCardId}
              setIsNewPostOpen={setIsNewPostOpen}
              handleSanCardUpdate={handleSanCardUpdate}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Mountain;
