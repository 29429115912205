import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../context/userContext";
import "./sanCard.css";
import LikeArticle from "../likeArticle/LikeArticle";
import { db } from "../../firebase";
import { deleteDoc, doc } from "firebase/firestore";
import {
  Button,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  CardActions,
} from "@mui/material";
import { 
  Edit as EditIcon,
  Delete as Deleteicon,
  ChatOutlined as ChatOutlinedIcon,
} from "@mui/icons-material";

// Children of Posts components
const SanCard = ({ fetchedPost, handleSanCardUpdate }) => {
  const [isSameUser, setIsSameUser] = useState(false);
  const { user, setIsLoginModalOpen } = useUserContext();
  const navigate = useNavigate();
// less than 90 characters
  let len = 90;
  let shortedDetails;
  let str = String(fetchedPost.courseDetail);
  if (str.length > len) {
    shortedDetails = str.slice(0, len) + "...";
  } else {
    shortedDetails = str;
  }
// 요일 한글표기
  const da = new Date(fetchedPost.dateTime.seconds * 1000);
  const getDayOfWeek = (d) => {
    const week = ["일", "월", "화", "수", "목", "금", "토"];
    const dayOfWeek = week[new Date(da).getDay()];
    return dayOfWeek;
  };
// 같은 유저확인 (편집/삭제 icons 표시)
  useEffect(() => {
    if (user === null) {
      setIsSameUser(false);
    } else if (user.email === fetchedPost.writtenBy) {
      setIsSameUser(true);
    }
  }, [user, fetchedPost.writtenBy]);

  const handleCardDelete = async (id) => {
    if (window.confirm("삭제 하시겠습니까?")) {
      try {
        const docRef = doc(db, fetchedPost.sanname, id);
        await deleteDoc(docRef);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const firstLetter = (kindy) => {
    if (!null) {
      return kindy[0];
    }
    return "킹";
  };
  
  const handleFullInfoBtn = () => {
    if (user) {
      navigate(`/singlecard/${fetchedPost.sanCardId}`, {
        state: {
          fetchedPost,
        },
      });
    } else {
      setIsLoginModalOpen(true);
    }
  };
  return (
      <div className="san__card__container">
        <Card className="card__item" sx={{ width: 340 }}>
          <CardHeader
            avatar={
              <Avatar sx={{ backgroundColor: "secondary.light" }}>
                {firstLetter(fetchedPost.kindy)}
              </Avatar>
            }
            title={
              // <span style={{ fontFamily: "Nanum Pen Script" }}>
              fetchedPost.course
              //  </span>
            }
            action={
              <span style={{ fontWeight: "bold" }}>{fetchedPost.kindy}</span>
            }
          />
          <CardContent>
            <div>
              <span className="YY">{`${da.getFullYear()}년`}</span>
              <span className="MM">{`${da.getMonth() + 1}월`}</span>
              <span className="DD">{`${da.getDate()}일`}</span>
              <span className="hh">{`${da.getHours()}시`}</span>
              <span className="mm">{`${da.getMinutes()}분`}</span>
              <span className="dd">{`${getDayOfWeek(da)}요일`}</span>
            </div>
            <div className="first">
              <span className="e__point">
                만남장소 : {fetchedPost.entryPoint}
              </span>
            </div>
            <div className="second">
              <span>종료장소 : {fetchedPost.endPoint}</span>
            </div>
            <div className="second">
              <span>리더 : {fetchedPost.leader}</span>
            </div>
            <div className="third">
              <span className="t_diffy">{fetchedPost.diffy}</span>
              <span className="t_dist">{fetchedPost.dist}</span>
              <span className="t_timmy">{fetchedPost.timmy}</span>
              <span className="t_fee">{fetchedPost.fee}원</span>
            </div>
            <div className="t_fourth">{shortedDetails}</div>
            <CardActions className="card__icon__box">
              <LikeArticle
                id={fetchedPost.sanCardId}
                sanname={fetchedPost.sanname}
                like={fetchedPost.like}
              />
              {isSameUser && (
                <div className="edit__delete">
                  <EditIcon
                    className="card__edit__icon"
                    onClick={() => handleSanCardUpdate(fetchedPost.sanCardId)}
                  />
                  <Deleteicon
                    className="card__delete__icon"
                    onClick={() => handleCardDelete(fetchedPost.sanCardId)}
                  />
                </div>
              )}
              <div className="comment__link">
                <Button variant="outlined" 
                  startIcon={<ChatOutlinedIcon />}
                  onClick={handleFullInfoBtn}
                  >
                  전체정보
                </Button>
              </div>
              
            </CardActions>
          </CardContent>
        </Card>
      </div>
  );
};

export default SanCard;
