import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Geoposition from "./pages/geoposition/Geoposition";
import SingleCard from "./pages/singleCard/SingleCard";
import Mountain from "./pages/mountains/Mountain";
import NotFound from "./pages/notFound/NotFound";
import NewPost from "./components/newPost/NewPost";
import MapSearch from "./pages/mapSearch/MapSearch";


function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mountains/:sanname" element={<Mountain />} />
        <Route path="/singlecard/:sanCardId" element={<SingleCard />} />
        <Route path="/newPost" element={<NewPost />} />
        <Route path="/geoposition" element={<Geoposition />} />
        <Route path="/mapsearch" element={<MapSearch />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
