import React, { useState, useEffect } from "react";
import "./attendee.css";
import {
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  updateDoc,
  // setDoc,addDoc,collection, serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase/index";
import { useUserContext } from "../../context/userContext";
const Attendee = ({ sanname, sanCardId}) => {
  //   프롭으로 넘어오는 배열을 인식 못한다고, 시간낭비 -- {} 잊음
  const [attendees, setAttendees] = useState([]);
  const [postDisplayName, setPostDisplayName] = useState("");
  const [isIn, setIsIn] = useState(false);
  const { user } = useUserContext();
// attendees : 참석자 명단
// postDisplayName : 포스트 작성자, leader
// displayName: 현재 user의 이름
// postDisplayName: post 한 사람의 이름
  const docRef = doc(db, sanname, sanCardId);
//let attendeeRef = doc(db, sanname, sanCardId);

  useEffect(() => {
    const fetchAttendees = async () => {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setAttendees(docSnap.data().attendees);
        setPostDisplayName(docSnap.data().postDisplayName);
      } else {
        console.log("데이터 없음");
      }
    };
    fetchAttendees();
  }, [isIn,docRef]);

  const handleAttendees = async () => {
    if (!attendees?.includes(user?.displayName)) {
      await updateDoc(docRef, {
        attendees: arrayUnion(user.displayName),
      })
        .then(() => setIsIn(true))
        .catch((e) => {
          setIsIn(false)
          console.log(e)
        }
       );
    } else {
      await updateDoc(docRef, {
        attendees: arrayRemove(user.displayName),
      })
        .then(() => setIsIn(false))
        .catch((e) => console.log(e));
    }
  };
  return (
    <>
      {user && user.displayName !== postDisplayName ? 
        (
          !attendees?.includes(user.displayName) ? 
            (
              <button
                type="text"
                className={`attendee__${
                  attendees?.includes(user.displayName) ? "yes" : "no"
                }`}
                onClick={handleAttendees}
                >
                참석 ?
              </button>
            )
          : (
              <button
                type="text"
                className="attendee__yes"
                onClick={()=>{handleAttendees()}}
              >
                참가신청완료 - 취소 ?
                </button>
            )
        ) 
        : ( 
            <div className="attendee__list">
              참석신청명단: { attendees?.toString(",")}
            </div>
          )
      }
    </>
  );
};

export default Attendee;

// const handleAttend = async () => {
//     await updateDoc(attendeeRef, {
//       attendees: arrayUnion(user.displayName),
//     })
//       .then(() => console.log("참석"))
//       .catch((e) => console.log(e));
//   };
//   const handleAbsent = async () => {
//     await updateDoc(attendeeRef, {
//       attendees: arrayRemove(user.displayName),
//     })
//       .then(() => console.log("불참"))
//       .catch((e) => console.log(e));
//   };

// {user && attendees.includes(user.displayName) ? (
//     <button className="attend" onClick={handleAbsent}>
//       참석취소
//     </button>
//   ) : (
//     <button className="absent" onClick={handleAttend}>
//       참석하겠습니다
//     </button>
//   )}
