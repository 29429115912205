import React, { useState } from "react";
import San from "../components/san/San";
import Categories from "../components/categories/Categories";
import Footer from "../components/footer/Footer";
import Topbar from "../components/topbar/Topbar";
import { useUserContext } from "../context/userContext";
// manually touched
import Notice from "../components/notice/Notice";
import getSans from "../data/san221113";
import seoul from "../data/seoul.json";
// sans is full list of 산 sorted through excel sheet
const sans = getSans();
const allProvinces = sans.map((san) => san.province);
const uniqueProvinces = [
  "서울특별시",
  "산림청100",
  "블랙야크100",
  ...new Set(allProvinces),
  // "all",
];

const Home = () => {
  const { loading } = useUserContext();
  const [sanItems, setSanItems] = useState(seoul);
  const [isDark, setIsDark] = useState(false);
  const [isNotice, setIsNotice] = useState(false);

  // sans는 모든 산의 리스트
  // sanItems는 필터된 산의 리스트
  // sanlim100는 산림청 100
  // black100은 블랙야크 100
  const filterItems = (province) => {
    if (province === "all") {
      // all은 스트링이므로 '' 잊지말것
      setSanItems(sans);
      return;
    }
    if (province === "산림청100") {
      const sanlim100 = sans.filter((san) => {
        return san.san100 === true;
      });
      setSanItems(sanlim100);
      return;
    }
    if (province === "블랙야크100") {
      const black100 = sans.filter((san) => {
        return san.black100 === true;
      });
      setSanItems(black100);
      return;
    }
    const newSans = sans.filter((san) => {
      return san.province === province;
    });
    setSanItems(newSans);
  };
  return (
      <main>
        {loading && <p> Loading ...</p>}
{/* 해더 영역 */}
        <Topbar />
{/* Category 영역 */}
        <div className={isDark ? "category dark" :"category"}>
            <div className="category__container">
              <Categories
                uniqueProvinces={uniqueProvinces}
                filterItems={filterItems}
              />
            </div>
        </div>
{/* Main Section(산행정보 리스트) 과 Footer영역 */}
        <div className={isDark ? "wrapper dark" : "wrapper"}>
          <section className="section__container">
            <San sanItems={sanItems} setIsDark={setIsDark} isDark={isDark} />
          </section>
        </div>
        <Footer isDark={isDark}/>
{/* Notice Modal Window */}
        {isNotice && (
          <div className="modal">
            <div className="modal__box">
              <Notice setIsNotice={setIsNotice} isNotice={isNotice} />
            </div>
          </div>
        )}
      </main>
  );
};

export default Home;
